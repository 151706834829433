import { UserState, AuthAction, User } from 'cellar/src/ts-types'
import { LOGIN, LOGOUT } from './types'
import { clearStorage } from '../../utils'

export const authReducer = (
  state: UserState,
  action: AuthAction
): UserState => {
  switch (action.type) {
    case LOGIN:
      return {
        email: (action as { payload: { email: User } }).payload.email,
        isAuthenticated: true
      }
    case LOGOUT:
      clearStorage()
      return {
        email: null,
        isAuthenticated: false
      }
    default:
      return state
  }
}
