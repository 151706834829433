import React, { FC } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { QuizOptionType } from '../../ts-types'
import { deepClone } from '../../utils'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Typography from '@mui/material/Typography'

interface IQuestsSelectorProps {
  options: QuizOptionType[]
  onChange: (state: QuizOptionType[]) => void
  error: string
  multiple?: boolean
}

const QuizOptions: FC<IQuestsSelectorProps> = ({
  options,
  onChange,
  multiple,
  error
}) => {
  const handleInputChange = (
    index: number,
    name: string,
    value: string | boolean
  ) => {
    let newOptions = [...options]
    if (name === 'input') {
      //@ts-ignore
      newOptions[index] = { ...newOptions[index], text: value, error: !value }
    } else {
      if (multiple) {
        //@ts-ignore
        newOptions[index] = { ...newOptions[index], isCorrect: value }
      } else {
        newOptions = newOptions.map((el, i) => {
          return { ...el, isCorrect: i === index }
        })
      }
    }
    onChange(newOptions)
  }

  const addNewAnswer = () => {
    onChange([
      ...options,
      {
        isCorrect: false,
        text: '',
        error: false
      }
    ])
  }

  const onDelete = (index: number) => {
    const newOptions = [...options]
    newOptions.splice(index, 1)
    onChange(newOptions)
  }

  return (
    <>
      {options.map((option, index) => (
        <Box
          key={index + 'options'}
          sx={{ display: 'flex', marginBottom: '5px' }}
        >
          <Checkbox
            checked={option.isCorrect}
            onChange={(e) => {
              handleInputChange(index, 'checkbox', e.target.checked)
            }}
          />
          <TextField
            fullWidth
            size="small"
            name="quiz_text"
            label={'Option ' + (index + 1)}
            value={option.text}
            error={!!option.error}
            onChange={(e) => {
              handleInputChange(index, 'input', e.target.value)
            }}
          />
          <Box alignContent="center" sx={{ paddingLeft: '5px' }}>
            {options.length > 2 && (
              <DeleteOutlineOutlinedIcon
                color="error"
                cursor="pointer"
                onClick={() => {
                  onDelete(index)
                }}
              />
            )}
          </Box>
        </Box>
      ))}
      {error ? (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      ) : (
        ''
      )}
      {options.length < 6 && (
        <Box textAlign="center">
          <Button
            size="small"
            variant="outlined"
            onClick={addNewAnswer}
            color="primary"
          >
            ADD NEW ANSWER
          </Button>
        </Box>
      )}
    </>
  )
}

export default QuizOptions
