import React, { FC, useEffect, useRef } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  Header as HeaderContainer,
  SearchBlock
} from 'cellar/src/globalStyles/styles'
import Search from 'cellar/src/components/Search'
import QuestsSelector from 'cellar/src/components/QuestsSelector'

interface IHeaderProps {
  query: string
  setQuery: (value: string) => void
  setCreation: () => void
  onFilter: (name: string, value: string | null) => void
  questValue: string
  setQuestValue: (value: string) => void
}

const Header: FC<IHeaderProps> = ({
  query,
  setQuery,
  onFilter,
  setCreation,
  questValue,
  setQuestValue
}) => {
  const isFirstRender = useRef(true)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const onSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(target.value)
  }

  const onAutocompleteTypeChange = (
    event: React.SyntheticEvent,
    value: string
  ) => {
    onFilter('questId', value)
    setQuestValue(value ? value : '')
  }

  useEffect(() => {
    if (!isFirstRender.current) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        onFilter('stepsQuery', query)
      }, 500)
    } else {
      isFirstRender.current = false
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [query])

  return (
    <>
      <HeaderContainer>
        <Box display="flex">
          <SearchBlock>
            <Search value={query} onChange={onSearch} />
          </SearchBlock>
          <Box sx={{ width: '200px' }}>
            <QuestsSelector
              size="small"
              error={false}
              name="questId"
              onInputChange={onAutocompleteTypeChange}
            />
          </Box>
        </Box>

        <Typography component="h5">STEPS</Typography>

        <Button
          size="small"
          sx={{ width: '150px' }}
          variant="contained"
          onClick={() => setCreation()}
        >
          <Typography variant="body2">CREATE</Typography>
        </Button>
      </HeaderContainer>
    </>
  )
}

export default Header
