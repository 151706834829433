import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const ShowIcon = styled.div`
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`
