import styled from 'styled-components'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

export const CustomCard = styled(Card)(() => ({
  width: '100%',
  maxWidth: '300px',
  maxHeight: '200px',
  cursor: 'pointer'
}))

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:hover': {
    '& .MuiTypography-root': {
      color: '#A60063'
    },
    '& .MuiSvgIcon-root': {
      color: '#A60063'
    }
  }
}))
