import React, { useEffect, useState } from 'react'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { ContainerItem, LoadingWrapper } from 'cellar/src/globalStyles/styles'
import { IListQuestResponse, IQuest } from '@repo/types'
import { getQuests } from '../../api'
import Header from './components/Header'
import { useQueryParams } from '../../hooks'
import Modals from './components/Modals'
import Body from './components/Body'
import PaginationWrapper from '../../components/PaginationWrapper'
import { ErrorType, PageType } from '../../ts-types'
import LinearProgress from '@mui/material/LinearProgress'
import { StatusModalType } from './ts-types'

function Quests() {
  const { getQueryParam, setQueryParam, setQueryParams } = useQueryParams()
  const [query, setQuery] = useState<string>(getQueryParam('query') || '')
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [creationModal, setCreationModal] = useState<boolean | IQuest>(false)
  const [deleteModal, setDeleteModal] = useState<boolean | string>(false)
  const [statusModal, setStatusModal] = useState<boolean | StatusModalType>(
    false
  )
  const [state, setState] = useState<null | IListQuestResponse>(null)
  const [pageData, setPageData] = useState<PageType>({
    page: Number(getQueryParam('page')) || 1,
    perPage: Number(getQueryParam('perPage')) || 10
  })

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const openEditModal = (quest: IQuest) => {
    setCreationModal(quest)
  }

  const openDeleteModal = (id: string) => {
    setDeleteModal(id)
  }

  const filterDataByQuery = (query: string) => {
    setQueryParams([
      { key: 'page', value: '1' },
      { key: 'perPage', value: '10' },
      { key: 'query', value: query }
    ])
    getQuestsData(1, 10, query)
  }

  const afterQuestCreation = (quest?: IQuest) => {
    setQueryParams([
      {
        key: 'query',
        value: ''
      },
      { key: 'page', value: '1' },
      { key: 'perPage', value: '10' }
    ])
    setQuery('')
    setPageData({ page: 1, perPage: 10 })
    getQuestsData(1, 10, query)
    setSuccessMessage('Quest successfully created')
  }

  const getQuestsData = async (
    page: number,
    perPage: number,
    query: string
  ) => {
    setLoading(true)
    try {
      const data = await getQuests({
        page: page,
        perPage: perPage,
        query: query
      })

      setState(data)
    } catch (error: unknown) {
      // const message: ErrorType = getErrorMessage(error)
      // setErrorMessage(message)
    } finally {
      stopLoading()
    }
  }

  const pageChange = (
    e: React.ChangeEvent<unknown> | null,
    page: number,
    fromModal?: boolean
  ) => {
    if (page !== pageData.page || fromModal) {
      setQueryParam('page', page.toString())
      setLoading(true)
      setPageData({ ...pageData, page: page })
      getQuestsData(page, pageData.perPage, query)
    }
  }

  const onAfterDelete = () => {
    if (state?.data.length === 1 && pageData.page != 1) {
      setQueryParam('page', (pageData.page - 1).toString())
      getQuestsData(pageData.page - 1, pageData.perPage, query)
      setPageData({ ...pageData, page: pageData.page - 1 })
    } else {
      getQuestsData(pageData.page, pageData.perPage, query)
    }
  }

  const afterStatusUpdate = (value: any) => {
    if (statusModal && state?.data) {
      const index = state?.data.findIndex(
        (el) => el._id === (statusModal as StatusModalType).id
      )
      if (index > -1) {
        const newState = { ...state }
        newState.data[index].status = value
        setState(newState)
        setStatusModal(false)
      }
    }
  }

  useEffect(() => {
    getQuestsData(pageData.page, pageData.perPage, query)
  }, [])

  return (
    <>
      <FadeTransition>
        <ContainerItem>
          <Header
            onSearch={onSearch}
            query={query}
            setCreationModal={setCreationModal}
            filterDataByQuery={filterDataByQuery}
          />
          <LoadingWrapper>
            {!state || loading ? <LinearProgress /> : ''}
          </LoadingWrapper>
          {state ? (
            <Body
              setStatusModal={setStatusModal}
              state={state.data}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          ) : (
            ''
          )}

          {state ? (
            <div>
              <PaginationWrapper
                count={state?.pageCount}
                page={pageData.page}
                onChange={pageChange}
              />
            </div>
          ) : (
            ''
          )}
          <Modals
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            onAfterDelete={onAfterDelete}
            afterQuestCreation={afterQuestCreation}
            setCreationModal={setCreationModal}
            createEditData={creationModal}
            setStatusModal={setStatusModal}
            statusModal={statusModal}
            afterUpdate={afterStatusUpdate}
          />
        </ContainerItem>
      </FadeTransition>
    </>
  )
}

export default Quests
