import React, { FC, useContext } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { IProject, ProjectStatusEnum } from '@repo/types'
import FadeTransition from 'cellar/src/components/FadeTransition'
import Actions from 'cellar/src/components/Actions'
import TableWrapper from 'cellar/src/components/TableWrapper'
import { noResultFound, projectsHeadRows } from 'cellar/src/constants'
import StringCuter from 'cellar/src/components/StringCuter'
import { NoResultFound } from 'cellar/src/globalStyles/styles'
import Image from 'cellar/src/components/UI/Image'
import { ProjectTypeContext } from 'cellar/src/contexts/projectTypes'
import Button from '@mui/material/Button'
import { StatusModalType } from '../ts-types'

interface IBodyProps {
  state: IProject[]
  openEditModal: (project: IProject) => void
  openDeleteModal: (id: string) => void
  setStatusModal: (statusData: StatusModalType) => void
}

const Body: FC<IBodyProps> = ({
  state,
  openEditModal,
  openDeleteModal,
  setStatusModal
}) => {
  const context = useContext(ProjectTypeContext)

  const getProjectType = (id: string) => {
    return context.state.hashMap[id] ? context.state.hashMap[id].name : ''
  }

  const onStatusChange = (statusData: StatusModalType) => {
    setStatusModal(statusData)
  }

  const getStatus = (status: ProjectStatusEnum, project: IProject) => {
    switch (status) {
      case ProjectStatusEnum.ENABLED:
        return (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              onStatusChange({
                id: project._id as string,
                status: project.status
              })
            }}
          >
            ENABLED
          </Button>
        )
      case ProjectStatusEnum.DISABLED:
        return (
          <Button
            size="small"
            variant="outlined"
            color="warning"
            onClick={() => {
              onStatusChange({
                id: project._id as string,
                status: project.status
              })
            }}
          >
            DISABLED
          </Button>
        )
    }
  }

  return (
    <FadeTransition>
      <div>
        {state.length ? (
          <TableWrapper headRows={projectsHeadRows}>
            {state.map((project: IProject) => (
              <TableRow
                key={project._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Image src={project.logo} width={30} height={30} alt="logo" />
                </TableCell>
                <StringCuter value={project.name} />
                <StringCuter value={project.description} />
                <StringCuter
                  value={getProjectType(project.typeId as unknown as string)}
                />
                <StringCuter value={project.website} />
                <TableCell>{getStatus(project.status, project)}</TableCell>
                <TableCell>
                  <Actions
                    onDelete={() => {
                      openDeleteModal(project._id as string)
                    }}
                    onEdit={() => {
                      openEditModal(project)
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableWrapper>
        ) : (
          <NoResultFound variant="h3">{noResultFound}</NoResultFound>
        )}
      </div>
    </FadeTransition>
  )
}

export default Body
