import React, { FC, useState } from 'react'
import CustomizedDialogs from '../UI/Dialog'
import Typography from '@mui/material/Typography'

interface IPageHeader {
  text: string
  handleClose: () => void
  onYes: (setLoading: (value: boolean) => void) => void
  open: boolean
}

const DeleteModal: FC<IPageHeader> = ({ open, handleClose, text, onYes }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const onConfirm = () => {
    setLoading(true)
    onYes(setLoading)
  }

  return (
    <CustomizedDialogs
      open={open}
      handleClose={handleClose}
      title="Delete"
      confirmTitle="Yes"
      onConfirmColor="error"
      onConfirm={onConfirm}
      disabled={loading}
    >
      <Typography>
        {text ? (
          text
        ) : (
          <>
            <div>Are you sure you want to delete?</div>
            <div>This action cannot be undone.</div>
          </>
        )}
      </Typography>
    </CustomizedDialogs>
  )
}

export default DeleteModal
