import React, { FC, useEffect, useState } from 'react'
import FadeTransition from 'cellar/src/components/FadeTransition'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import { stepOptions } from 'cellar/src/constants'
import { Header as HeaderContainer } from 'cellar/src/globalStyles/styles'
import QuestsSelector from 'cellar/src/components/QuestsSelector'
import BasicSelect from 'cellar/src/components/UI/Select'
import {
  allValidateValue,
  getErrorMessage,
  isNumberFn,
  stepsMainStateInitialFields
} from 'cellar/src/utils'
import {
  createOffChainStep,
  createOnChainStep,
  editOffChainStep,
  getStep
} from 'cellar/src/api'
import { ICreationForm } from '../ts-types'
import OffChainForm from './OffChainForm'
import OnChainForm from './OnChainForm'
import { ErrorType } from '../../../ts-types'
import {
  ICreateOffChainStepInput,
  ICreateOnChainStepInput,
  IOffChainStepInput,
  IOnChainStepInput,
  IStep,
  StepTypeEnum
} from '@repo/types'
import Loading from '../../../components/Loading'
import { useQueryParams } from '../../../hooks'

interface ICreationFormProps {
  setCreation: (message?: string) => void
  data: string | boolean
  afterCreation?: () => void
  creationDisabled?: boolean
}

const CreationForm: FC<ICreationFormProps> = ({
  setCreation,
  data,
  afterCreation,
  creationDisabled
}) => {
  const { getQueryParam } = useQueryParams()
  const selectedQuest: string | undefined | null = getQueryParam('quest-id')
  const isEdit: boolean = data !== 'Create'
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [editStep, setEditStep] = useState<IStep | null>()

  const [mainState, setMainState] = useState<ICreationForm>(
    stepsMainStateInitialFields(null, selectedQuest)
  )

  const [loading, setLoading] = useState<boolean>(false)

  const mainStateChange = (name: string, value: string | number) => {
    setMainState({
      ...mainState,
      [name]: {
        touched: true,
        error: !value,
        value: value,
        validations: { required: true }
      }
    })
  }

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getChildState = <T extends IOffChainStepInput | IOnChainStepInput>(
    state: T
  ): void => {
    setLoading(true)

    //@ts-ignore
    const addressIsValid = allValidateValue<ICreationForm>(mainState)

    if (addressIsValid) {
      setMainState(addressIsValid as ICreationForm)
      stopLoading()
    } else {
      const isOnChain = mainState.type.value === 'on_chain'
      const body: ICreateOnChainStepInput | ICreateOffChainStepInput = {
        quest: mainState.quest.value,
        type: mainState.type.value as StepTypeEnum,
        xp: Number(mainState.xp.value),
        ...(isOnChain
          ? { onChainStep: state as IOnChainStepInput }
          : { offChainStep: state as IOffChainStepInput })
      }
      ;(isOnChain
        ? createOnChainStep(body as ICreateOnChainStepInput)
        : isEdit
          ? editOffChainStep(body as ICreateOffChainStepInput, data as string)
          : createOffChainStep(body as ICreateOffChainStepInput)
      )
        .then(() => {
          if (afterCreation) {
            afterCreation()
          } else {
            setTimeout(() => {
              setCreation(
                isEdit
                  ? 'Step successfully edited'
                  : 'Step successfully created'
              )
            }, 1000)
          }
        })
        .catch((error: unknown) => {
          const message: ErrorType = getErrorMessage(error)
          setErrorMessage(message)
          setTimeout(() => {
            setErrorMessage('')
          }, 8000)
          stopLoading()
        })
    }
  }

  useEffect(() => {
    if (isEdit) {
      ;(async () => {
        setLoading(true)
        try {
          const step = await getStep(data as string)
          setMainState(stepsMainStateInitialFields(step))
          setEditStep(step)
          stopLoading()
        } catch (error: unknown) {
          const message: ErrorType = getErrorMessage(error)
          setErrorMessage(message)
        }
      })()
    } else {
      setEditStep(null)
    }
  }, [])

  return (
    <FadeTransition>
      <Box sx={{ paddingBottom: '20px' }}>
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid size={{ xs: 12 }}>
            <Typography component="h5" textAlign="left">
              Main Information
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <QuestsSelector
              defaultValue={mainState.quest.value}
              disabled={
                loading || !!selectedQuest || !!creationDisabled || isEdit
              }
              error={mainState.quest.error}
              name="quest"
              onInputChange={(e, value) => {
                mainStateChange('quest', value)
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <BasicSelect
              fullWidth
              disabled={loading || isEdit || !!creationDisabled}
              withNone={false}
              value={mainState.type.value}
              values={stepOptions}
              handleChange={({ target }) => {
                mainStateChange(target.name, target.value)
              }}
              title="Type"
              label="Type"
              name="type"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <TextField
              fullWidth
              name="xp"
              disabled={loading || !!creationDisabled}
              label="XP"
              value={mainState.xp.value}
              helperText={mainState.xp.error}
              error={!!mainState.xp.error}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNumberFn(e.target.value) || e.target.value === '') {
                  mainStateChange('xp', e.target.value)
                }
              }}
            />
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Divider sx={{ marginTop: '10px' }} />
          </Grid>

          {editStep || editStep == null ? (
            <Grid size={{ xs: 12 }}>
              {mainState.type.value === 'on_chain' ? (
                <OnChainForm
                  creationDisabled={creationDisabled}
                  data={
                    editStep
                      ? editStep.onChainStep
                        ? editStep.onChainStep
                        : null
                      : null
                  }
                  errorMessage={errorMessage}
                  disabled={loading || !!creationDisabled}
                  confirmTitle={isEdit ? 'SAVE' : 'CREATE'}
                  sendState={getChildState}
                />
              ) : (
                <OffChainForm
                  data={
                    editStep
                      ? editStep.offChainStep
                        ? editStep.offChainStep
                        : null
                      : null
                  }
                  errorMessage={errorMessage}
                  disabled={loading || !!creationDisabled}
                  confirmTitle={isEdit ? 'SAVE' : 'CREATE'}
                  sendState={getChildState}
                />
              )}
            </Grid>
          ) : (
            <Grid size={{ xs: 12 }}>
              <Loading />
            </Grid>
          )}
        </Grid>
      </Box>
    </FadeTransition>
  )
}

export default CreationForm
