import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import FadeTransition from 'cellar/src/components/FadeTransition'
import CreationForm from './CreationForm'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Header as HeaderContainer } from '../../../globalStyles/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { QUESTS } from '../../../constants'
import { useQueryParams } from '../../../hooks'

interface ICreationFormProps {
  setCreation: (message?: string) => void
  data: string | boolean
}

type StateType = {
  disabled: boolean
  created: boolean
  expanded: boolean
}

const Creation: FC<ICreationFormProps> = ({ setCreation, data }) => {
  const { getQueryParam } = useQueryParams()
  const fromQuestPage = getQueryParam('quest-id')
  const navigate = useNavigate()
  const [state, setState] = useState<StateType[]>([
    {
      disabled: false,
      created: false,
      expanded: true
    }
  ])

  const onClose = () => {
    if (fromQuestPage) {
      navigate('/' + QUESTS)
    } else {
      setCreation()
    }
  }

  const handleChange = (index: number) => {
    const newState = [...state]
    newState[index] = { ...state[index], expanded: !state[index].expanded }
    setState(newState)
  }

  const createNewStep = () => {
    setState([
      ...state,
      {
        disabled: false,
        created: false,
        expanded: true
      }
    ])
  }

  const handleDelete = (index: number) => {
    const newState = [...state]
    newState.splice(index, 1)
    setState(newState)
  }

  const afterCreation = (index: number) => {
    const newState = [...state]
    newState[index] = { disabled: false, expanded: false, created: true }
    setState(newState)
  }

  return (
    <FadeTransition>
      <div>
        <HeaderContainer>
          <Button
            size="small"
            sx={{ width: '150px' }}
            variant="contained"
            onClick={onClose}
          >
            <Typography variant="body2">BACK</Typography>
          </Button>
          <Typography component="h5">CREATE NEW STEP</Typography>
        </HeaderContainer>

        {!fromQuestPage ? (
          <CreationForm data={data} setCreation={setCreation} />
        ) : (
          <>
            {state.map((step, index) => (
              <Box sx={{ marginTop: '10px' }} key={`Accordion${index}`}>
                <FadeTransition>
                  <Accordion
                    expanded={step.expanded}
                    disabled={step.disabled}
                    onChange={() => {
                      handleChange(index)
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>STEP {index + 1}</Typography>
                      <Box
                        sx={{
                          marginLeft: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '10px'
                        }}
                      >
                        {step.created ? (
                          <Typography color="success">Created</Typography>
                        ) : state.length > 1 ? (
                          <DeleteIcon
                            fontSize="medium"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleDelete(index)
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                      <CreationForm
                        creationDisabled={step.created}
                        data={data}
                        setCreation={setCreation}
                        afterCreation={() => {
                          afterCreation(index)
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </FadeTransition>
              </Box>
            ))}

            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
              <Button
                variant="outlined"
                onClick={createNewStep}
                color="primary"
              >
                CREATE NEW STEP
              </Button>
            </Box>
          </>
        )}
      </div>
    </FadeTransition>
  )
}

export default Creation
