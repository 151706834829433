import React, { FC, ReactElement } from 'react'
import Tooltip from '@mui/material/Tooltip'

interface IBasicTooltip {
  title: string
  children: ReactElement
}

const BasicTooltip: FC<IBasicTooltip> = ({ children, title }) => {
  return <Tooltip title={title}>{children}</Tooltip>
}

export default BasicTooltip
