import React, { FC } from 'react'
import TableCell from '@mui/material/TableCell'
import BasicTooltip from '../UI/BasicTooltip'

interface IStringCuter {
  limit?: number
  value: string
}

const StringCuter: FC<IStringCuter> = ({ limit = 30, value }) => {
  const isLonger: boolean = value ? value.length > limit : false

  return isLonger ? (
    <BasicTooltip title={value}>
      <TableCell>{value.slice(0, limit) + '...'}</TableCell>
    </BasicTooltip>
  ) : (
    <TableCell>{value}</TableCell>
  )
}

export default StringCuter
