import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import Box from '@mui/material/Box'
import { ImageListItem } from 'cellar/src/ts-types'
import { Container, ImgItem } from './styles'
import { sliceString } from '../../utils'

interface IImagesList {
  images: ImageListItem[]
  width: number | string
  height: number | string
  onDelete: (img: ImageListItem, index: number) => void
}

const ImagesList: FC<IImagesList> = ({ images, height, width, onDelete }) => {
  return (
    <Box sx={{ justifyItems: 'center' }}>
      {images.map((img, index) => (
        <Container key={img.src}>
          <Box sx={{ display: 'flex' }}>
            <ImgItem src={img.src} alt="IMG" width={18} height={18} />
            <Typography sx={{ fontSize: '12px' }}>
              {sliceString(img.name ? img.name : '', 20)}
            </Typography>
          </Box>
          <CancelIcon
            onClick={() => {
              onDelete(img, index)
            }}
            cursor="pointer"
            sx={{ color: '#E6E6E6', fontSize: '19px' }}
          />
        </Container>
      ))}
    </Box>
  )
}

export default ImagesList
