export enum ProjectTypeEnum {
  BRIDGE = 'Bridge',
  DEFI = 'DeFi',
  GAMES = 'Games',
  SOCIAL = 'Social',
  AI = 'AI'
}

export enum ProjectStatusEnum {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}
