import React, { FC } from 'react'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

interface ISimpleSnackbar {
  severity?: 'error' | 'warning' | 'success'
  message: string
  autoHideDuration?: number
  onClose: () => void
}

const MessageSnack: FC<ISimpleSnackbar> = ({
  severity,
  message,
  autoHideDuration,
  onClose
}) => {
  return (
    <Snackbar
      open={true}
      onClose={onClose}
      autoHideDuration={autoHideDuration ? autoHideDuration : 8000}
      message=""
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert variant="outlined" severity={severity ? severity : 'error'}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default MessageSnack
