import { FC, ReactElement } from 'react'
import Fade from '@mui/material/Fade'

interface IFadeTransition {
  children: ReactElement
}

const FadeTransition: FC<IFadeTransition> = ({ children }) => {
  return (
    <div>
      <Fade in={true} timeout={500}>
        <div>{children}</div>
      </Fade>
    </div>
  )
}

export default FadeTransition
