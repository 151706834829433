import React, { Dispatch, FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import Typography from '@mui/material/Typography'
import { IProject, IQuest } from '@repo/types'
import { ErrorType, ImageListItem } from '../../../ts-types'
import {
  checkDateIsValid,
  FormStateType,
  getErrorMessage,
  getFileFromUrl,
  isQuestsStateValid,
  projectInitialFields,
  getImagesList,
  questInitialFields,
  validateValue
} from '../../../utils'
import { ICreationForm } from '../ts-types'
import TextField from '@mui/material/TextField'
import CustomDatePicker from '../../../components/MuiDatePicker'
import ProjectSelector from '../../../components/ProjectSelector'
import InputFileUpload from '../../../components/UI/FileUpload'
import MessageSnack from '../../../components/MessageSnack'
import ImagesList from '../../../components/ImagesList'
import { createQuests, updateQuests } from '../../../api'
import { STEPS } from '../../../constants'

interface ICreateEditFormProps {
  data: boolean | IQuest
  handleClose: () => void
  onYes: (quest?: IQuest) => void
}

const MAX_FILE_SIZE = 10000000

const CreateEditForm: FC<ICreateEditFormProps> = ({
  data,
  handleClose,
  onYes
}) => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [imgErrors, setImgErrors] = useState<null | string[]>(null)
  const [state, setState] = useState<ICreationForm>(questInitialFields(data))

  console.log(state, 'state')

  const getImgFile = (value: File | string) => {
    if (typeof data === 'object' && typeof value === 'string') {
      return getFileFromUrl(value)
    } else {
      return value
    }
  }

  const formStateData = () => {
    const formData: any = new FormData()
    formData.append('title', state.title.value)
    formData.append('description', state.description.value)
    formData.append('projectId', state.project.value)
    formData.append('startDate', (state.startDate.value as Dayjs).toISOString())
    formData.append('endDate', (state.endDate.value as Dayjs).toISOString())
    state.images.value.forEach((img: string | File, index: number) => {
      formData.append('images', getImgFile(img))
    })

    return formData
  }

  const onConfirm = async () => {
    const newState = isQuestsStateValid(state)
    if (newState) {
      setState(newState)
    } else {
      try {
        const formData = formStateData()
        if (typeof data === 'object') {
          const res = await updateQuests(formData, (data as IQuest)._id)
          onYes(res)
        } else {
          const res = await createQuests(formData)
          navigate(
            `/${STEPS}?stepsPage=1&perPage=10&stepsQuery=&questId=&isForm=Create&quest-id=${res._id}`
          )
          // onYes()
        }
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
      }
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name in state) {
      //@ts-ignore
      const newState = validateValue(state, name, value)
      //@ts-ignore
      setState(newState)
    }
  }

  const onDateChange = (value: Dayjs | null, name: string) => {
    const newValue = checkDateIsValid(value)
    const newState = { ...state, [name]: newValue }
    setState(newState)
  }

  const onProjectChange = (event: React.SyntheticEvent, value: string) => {
    //@ts-ignore
    onChange({ target: { name: 'project', value: value } })
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation()
    const files = event.target.files

    if (files) {
      if (files.length + state.images.value.length > 5) {
        setImgErrors(['You can upload up to 5 images.'])
      } else {
        const errorMessage = []
        const validFiles: File[] = []
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          if (file.size > MAX_FILE_SIZE) {
            errorMessage.push(file.name)
          } else {
            validFiles.push(file)
          }
        }
        if (errorMessage.length) {
          setImgErrors([
            `Files ${errorMessage.join(', ')} exceeds the 10 MB limit.`
          ])
        }

        if (validFiles) {
          const newState: ICreationForm = { ...state }
          newState.images = {
            value: newState.images.value
              ? [...newState.images.value, ...validFiles]
              : [...validFiles],
            error: '',
            touched: false
          }
          setState(newState)
        }
      }
    }
  }

  const cleanErrorMessages = () => {
    setImgErrors(null)
  }

  const getImages = (): ImageListItem[] => {
    if (typeof state.images.value[0] === 'string') {
      return []
    } else {
      return state.images.value
        ? (state.images.value as File[]).map((file) => {
            console.log(file, 'file')
            return { src: URL.createObjectURL(file), name: file.name }
          })
        : []
    }
  }

  const onDelete = (img: ImageListItem, index: number) => {
    const newState = { ...state }
    newState.images.value.splice(index, 1)
    setState(newState)
  }

  useEffect(() => {
    if (typeof data === 'object') {
      ;(async () => {
        const result = await getImagesList(data.images)
        if (result.errors.length) {
          setImgErrors(result.errors)
        }
        setState({
          ...state,
          images: { ...state.images, value: result.result }
        })
      })()
    }
  }, [])

  return (
    <CustomizedDialogs
      open={!!data}
      maxWidth="sm"
      handleClose={handleClose}
      // disabled={loading}
      title={typeof data === 'object' ? 'EDIT QUEST' : 'CREATE QUEST'}
      confirmTitle="Yes"
      onConfirm={onConfirm}
    >
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            autoFocus
            fullWidth
            label="Title"
            name="title"
            value={state.title.value}
            error={!!state.title.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <ProjectSelector
            defaultValue={state.project.value}
            error={state.project.error}
            name="project"
            onInputChange={onProjectChange}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={state.description.value}
            error={!!state.description.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <CustomDatePicker
            value={state.startDate.value}
            error={state.startDate.error}
            onChange={onDateChange}
            maxDate={state.endDate.value ? state.endDate.value : undefined}
            label="Start Date"
            fullWidth={true}
            name="startDate"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <CustomDatePicker
            value={state.endDate.value}
            error={state.endDate.error}
            onChange={onDateChange}
            minDate={state.startDate.value ? state.startDate.value : undefined}
            label="End Date"
            fullWidth={true}
            name="endDate"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ display: 'block' }}>
            <InputFileUpload
              error={state.images.error as string}
              onChange={handleFileChange}
            />
            {state.images.value ? (
              <ImagesList
                images={getImages()}
                width={18}
                height={18}
                onDelete={onDelete}
              />
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid size={{ xs: 12 }}>
          {errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      {imgErrors ? (
        <MessageSnack
          message={imgErrors.join(', ') as string}
          severity="error"
          onClose={cleanErrorMessages}
        />
      ) : (
        ''
      )}
    </CustomizedDialogs>
  )
}

export default CreateEditForm
