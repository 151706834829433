import React, { FC } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { SelectValuesItem } from 'cellar/src/ts-types'

interface IBasicSelect {
  title: string
  value: string | any
  label: string
  handleChange: (e: any) => void
  values: SelectValuesItem[]
  fullWidth?: boolean
  name: string
  error?: string | boolean
  helperText?: string
  required?: boolean
  size?: 'small' | 'medium'
  withNone?: boolean
  disabled?: boolean
}

const BasicSelect: FC<IBasicSelect> = ({
  title,
  values,
  value,
  label,
  handleChange,
  fullWidth,
  name,
  error,
  helperText,
  required,
  size,
  withNone = true,
  disabled
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        required={!!required}
        fullWidth={!!fullWidth}
        size={size ? size : 'medium'}
      >
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId={
            size === 'small'
              ? 'demo-simple-select-label'
              : 'demo-simple-select-label'
          }
          id="demo-simple-select"
          value={value}
          label={label}
          error={!!error}
          disabled={!!disabled}
          size={size ? size : 'medium'}
          onChange={handleChange}
          name={name}
        >
          {withNone && <MenuItem value="">None</MenuItem>}
          {values.map((el) => (
            <MenuItem key={el.value} value={el.value}>
              {el.title}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: 'red' }}>
          {helperText ? helperText : ''}
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

export default BasicSelect
