import React, { FC, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid2'
import { ErrorType } from 'cellar/src/ts-types'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import {
  allValidateValue,
  FormStateType,
  getErrorMessage,
  userFormFields,
  validateValue
} from 'cellar/src/utils'
import PasswordInput from '../../../components/PasswordInput'
import { createUser, updateUser } from '../../../api'
import Typography from '@mui/material/Typography'
import { AdminRoleEnum, AdminStatusEnum, IAdmin } from '@repo/types'

interface ICreateEditForm {
  fields: IAdmin | undefined
  handleClose: () => void
  afterCreateEdit: (data: IAdmin) => void
  open: boolean
  title: string
}

const CreateEditForm: FC<ICreateEditForm> = ({
  fields,
  handleClose,
  afterCreateEdit,
  open,
  title
}) => {
  const [state, setState] = useState<FormStateType>(userFormFields(fields))
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name in state) {
      const newState: FormStateType = validateValue(state, name, value)
      setState(newState)
    }
  }

  const updateOrCreateUser = () => {
    ;(async () => {
      try {
        const email = state.email.value
        const nickname = state.nickname.value

        if (state.password) {
          const password = state.password.value
          const { id } = await createUser({ email, password, nickname })
          handleClose()

          //@ts-ignore
          afterCreateEdit({
            _id: id,
            email: email,
            //@ts-ignore
            nickname: nickname,
            role: AdminRoleEnum.ADMIN
          })
        } else {
          const { result } = await updateUser({
            id: (fields as IAdmin)._id,
            body: { email, nickname }
          })
          if (result) {
            handleClose()
            //@ts-ignore
            afterCreateEdit({
              email,
              //@ts-ignore
              nickname
            })
          }
        }
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        setLoading(false)
      }
    })()
  }

  const onConfirm = () => {
    setLoading(true)
    const result = allValidateValue<FormStateType>(state)
    if (result) {
      setState(result as FormStateType)
      setLoading(false)
    } else {
      updateOrCreateUser()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onConfirm()
    }
  }

  return (
    <CustomizedDialogs
      disabled={loading}
      maxWidth="xs"
      open={open}
      handleClose={handleClose}
      title={title}
      confirmTitle="Yes"
      onConfirm={onConfirm}
    >
      <form>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TextField
              autoFocus
              fullWidth
              label="Email"
              name="email"
              value={state.email.value}
              error={!!state.email.error}
              helperText={state.email.error}
              onChange={onChange}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              fullWidth
              label="Nickname"
              name="nickname"
              value={state.nickname.value}
              error={!!state.nickname.error}
              helperText={state.nickname.error}
              onChange={onChange}
            />
          </Grid>
          {state.password ? (
            <Grid size={{ xs: 12 }}>
              <PasswordInput
                value={state.password.value}
                name="password"
                error={state.password.error}
                onChange={onChange}
                onKeyDown={handleKeyDown}
              />
            </Grid>
          ) : (
            ''
          )}

          {errorMessage ? (
            <Grid>
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </form>
    </CustomizedDialogs>
  )
}

export default CreateEditForm
