import React, { FC } from 'react'
import { IProjectType } from '@repo/types'
import MessageSnack from 'cellar/src/components/MessageSnack'
import CreateEditForm from './CreateEditForm'
import { ErrorType } from 'cellar/src/ts-types'
import DeleteModal from 'cellar/src/components/DeleteModal'
import { projectTypeDeleteText } from 'cellar/src/constants'
import { deleteProjectTypes } from 'cellar/src/api'
import { getErrorMessage } from 'cellar/src/utils'
import { EditDataType } from '../ts-types'

interface IModalsProps {
  errorMessage: ErrorType
  setErrorMessage: (value: ErrorType) => void
  setSuccessMessage: (value: string) => void
  creationModal: EditDataType | boolean
  setCreationModal: (value: boolean) => void
  afterCreate: (newType: IProjectType, isEdit?: boolean) => void
  setDeleteModal: (value: string | boolean) => void
  successMessage: string
  deleteModal: string | boolean
  afterDelete: () => void
}

const Modals: FC<IModalsProps> = ({
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  creationModal,
  setCreationModal,
  afterCreate,
  deleteModal,
  setDeleteModal,
  afterDelete
}) => {
  const onYes = (newType: IProjectType) => {
    if (typeof creationModal === 'object') {
      setSuccessMessage('Project type successfully edited')
      afterCreate(newType, true)
      setCreationModal(false)
    } else {
      setSuccessMessage('New project type successfully created')
      setCreationModal(false)
      afterCreate(newType)
    }
  }

  const onDelete = () => {
    try {
      ;(async () => {
        const { result } = await deleteProjectTypes(deleteModal as string)
        if (result) {
          setSuccessMessage('Project type successfully deleted')
          setDeleteModal(false)
          afterDelete()
        }
      })()
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
    }
  }

  return (
    <>
      {errorMessage ? (
        <MessageSnack
          onClose={() => {
            setErrorMessage(null)
          }}
          severity="error"
          message={errorMessage}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <MessageSnack
          onClose={() => {
            setSuccessMessage('')
          }}
          severity="success"
          message={successMessage}
        />
      ) : (
        ''
      )}

      {creationModal && (
        <CreateEditForm
          data={creationModal}
          handleClose={() => setCreationModal(false)}
          onYes={onYes}
        />
      )}

      {deleteModal ? (
        <DeleteModal
          open={!!deleteModal}
          onYes={onDelete}
          handleClose={() => setDeleteModal(false)}
          text={projectTypeDeleteText}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Modals
