import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme'
  },
  colorSchemes: {
    light: {
      palette: {
        text: {
          primary: '#010f12',
          secondary: '#031419',
          error: '',
          warning: ''
        },
        primary: {
          // main: '#3184fa'
          main: '#A60063'
        },
        secondary: {
          main: '#e5e7f5'
        },
        error: {
          main: '#e57373'
        },
        warning: {
          main: '#ffb74d'
        },
        info: {
          main: '#4fc3f7'
        },
        success: {
          main: '#81c784'
        }
      }
    },
    dark: {
      palette: {
        text: {
          primary: '#e0e0e0',
          secondary: '#eeeeee',
          error: '',
          warning: ''
        },
        primary: {
          // main: '#00aeff'
          main: '#A60063'
        },
        secondary: {
          main: '#c7ccf4'
        },
        error: {
          main: '#d32f2f'
        },
        warning: {
          main: '#f57c00'
        },
        info: {
          main: '#0288d1'
        },
        success: {
          main: '#388e3c'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  // palette: {
  //   mode: 'dark'
  // },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 500 },
    h2: { fontSize: '2rem', fontWeight: 500 },
    h3: { fontSize: '1.75rem', fontWeight: 500 },
    body1: { fontSize: '1.25rem', fontWeight: 500 },
    body2: { fontSize: '1rem', lineHeight: 1.5 },
    button: { fontSize: '1rem', textTransform: 'none' }
  },
  spacing: 8,
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        root: {
          textTransform: 'none' // Example customization
        }
      }
    }
  }
})
