import React, { Dispatch, FC, useEffect, useRef } from 'react'
import { Header as HeaderContainer } from 'cellar/src/globalStyles/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Search from 'cellar/src/components/Search'
import {
  filterProjectTypes,
  initialProjectTypes
} from 'cellar/src/contexts/projectTypes/actions'
import { TypeContextAction } from 'cellar/src/contexts/projectTypes/ts-types'

interface IHeaderProps {
  query: string
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  setCreationModal: (value: boolean) => void
  filterDataByQuery: (query: string) => void
}

const Header: FC<IHeaderProps> = ({
  query,
  onSearch,
  setCreationModal,
  filterDataByQuery
}) => {
  const isFirstRender = useRef(true)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (!isFirstRender.current) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        filterDataByQuery(query)
      }, 300)
    } else {
      isFirstRender.current = false
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [query])

  return (
    <HeaderContainer>
      <Search value={query} onChange={onSearch} />
      <Typography component="h5">PROJECT TYPES</Typography>
      <Button
        size="small"
        sx={{ width: '150px' }}
        variant="contained"
        onClick={() => setCreationModal(true)}
      >
        <Typography variant="body2">CREATE</Typography>
      </Button>
    </HeaderContainer>
  )
}

export default Header
