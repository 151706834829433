import React, { FC, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { IProject } from '@repo/types'
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete'
import Typography from '@mui/material/Typography'
import { getProjects } from '../../api'
import { ErrorType } from '../../ts-types'
import { getErrorMessage } from '../../utils'
import CircularProgress from '@mui/material/CircularProgress'

interface IProjectSelector {
  onInputChange: (event: React.SyntheticEvent, value: string) => void
  name: string
  error: boolean | string
  defaultValue?: string
}

const ProjectSelector: FC<IProjectSelector> = ({
  onInputChange,
  name,
  error,
  defaultValue
}) => {
  const [errorMessage, setErrorMessage] = useState<ErrorType>('')
  const [projects, setProjects] = useState<null | IProject[]>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [inputValue, setInputValue] = useState<string>('')

  const getOptions = (): IProject[] => {
    return projects
      ? inputValue
        ? projects.filter((el) => el.name.includes(inputValue))
        : projects
      : []
  }

  const options: IProject[] = getOptions()

  const findProject = (name: string) => {
    const index = (projects as IProject[]).findIndex(
      (type) => type.name === name
    )
    return (projects as IProject[])[index]
  }

  const onInputChangeAfter = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    switch (reason) {
      case 'selectOption':
        const project = findProject(value)
        onInputChange(event, (project as IProject)._id as string)
        setInputValue(project.name)
        break
      case 'blur':
        setInputValue(value)
        if (value) {
          const project = findProject(value)
          const _id = project._id
          onInputChange(event, _id ? _id : '')
        } else {
          onInputChange(event, value)
        }
        break
      case 'input':
        setInputValue(value)
        if (!value) {
          onInputChange(event, '')
        }
        break
      case 'clear':
        setInputValue(value)
        onInputChange(event, '')
        break
      case 'removeOption':
        setInputValue(value)
        onInputChange(event, '')
        break
      default:
        // onInputChange(event, value)
        break
    }
  }

  useEffect(() => {
    try {
      ;(async () => {
        const data = await getProjects({})
        if (defaultValue) {
          const project = data.data.find((el) => el._id === defaultValue)
          if (project) {
            setInputValue(project.name)
          }
        }
        setProjects(data.data)
        setLoading(false)
      })()
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <>
      {errorMessage ? (
        <Typography color="error">{errorMessage}</Typography>
      ) : loading ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Autocomplete
          fullWidth
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          onInputChange={onInputChangeAfter}
          loading={loading}
          inputValue={inputValue}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField
              //@ts-ignore
              {...params}
              InputProps={{
                ...params.InputProps
              }}
              error={!!error}
              // helperText={error ? (error as string) : ''}
              name={name}
              label="Project"
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option._id}>
                {option.name}
              </li>
            )
          }}
        />
      )}
    </>
  )
}

export default ProjectSelector
