import React, {
  createContext,
  useReducer,
  FC,
  ReactNode,
  useEffect
} from 'react'
import { ErrorType } from 'cellar/src/ts-types'
import { getUsers } from 'cellar/src/api'
import { createHashMap, filterData, getErrorMessage } from 'cellar/src/utils'
import { useQueryParams } from 'cellar/src/hooks'
import { usersDefaultValues } from 'cellar/src/constants'
import { IInitialUserState, TypeContextAction } from './ts-types'
import { userReducer } from './reducer'
import { INITIAL_SETUP_USERS, SET_ERROR_MESSAGE } from './types'

export const UserContext = createContext<{
  state: IInitialUserState
  dispatch: React.Dispatch<TypeContextAction>
}>({
  state: { ...usersDefaultValues },
  dispatch: () => undefined
})

export const UsersProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getQueryParam } = useQueryParams()
  const [state, dispatch] = useReducer(userReducer, {
    ...usersDefaultValues
  })

  useEffect(() => {
    ;(async () => {
      try {
        const data = await getUsers()
        const hashMap = createHashMap(data.data, '_id')
        let filteredData = null
        if (getQueryParam('userQuery')) {
          filteredData = filterData(
            data.data,
            'email',
            getQueryParam('userQuery') as string
          )
        }

        dispatch({
          type: INITIAL_SETUP_USERS,
          payload: {
            hashMap: hashMap,
            data: {
              ...data,
              page: Number(getQueryParam('userPage'))
                ? Number(getQueryParam('userPage'))
                : 1
            },
            filteredData
          }
        })
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: message ? (message as string) : ''
        })
      }
    })()
  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  )
}
