import { getProjectTypes } from 'cellar/src/api'
import { ErrorType } from '../../ts-types'
import { createHashMap, filterData, getErrorMessage } from '../../utils'
import {
  GET_FILTERED_PROJECT_TYPES,
  ERROR_MESSAGE,
  GET_PROJECT_TYPES
} from './types'
import { Dispatch } from 'react'
import { IInitialProjectTypeState, TypeContextAction } from './ts-types'
import { IProjectType } from '@repo/types'
import { DELETE_ITEM } from '../users/types'

// export const initialProjectTypes = async (
//   dispatch: Dispatch<TypeContextAction>,
//   cb?: any
// ) => {
//   try {
//     const data = await getProjectTypes({ page: 1, perPage: 10, query: '' })
//     dispatch({ type: GET_PROJECT_TYPES, payload: data })
//     if (cb) {
//       cb()
//     }
//   } catch (error: unknown) {
//     const message: ErrorType = getErrorMessage(error)
//     dispatch({
//       type: ERROR_MESSAGE,
//       payload: (message ? message : '') as string
//     })
//     if (cb) {
//       cb()
//     }
//   }
// }

export const initialProjectTypes = async (
  dispatch: Dispatch<TypeContextAction>,
  query: string,
  page: number
) => {
  try {
    let filteredData = null
    const data = await getProjectTypes()
    if (query) {
      filteredData = filterData(data.data, 'name', query)
    }
    const pageCount = Math.ceil(
      Number((filteredData ? filteredData : data.data).length / 10)
    )
    const totalCount = Number(
      filteredData ? filteredData.length : data.totalCount
    )
    const hashMap = createHashMap(data.data, '_id')

    dispatch({
      type: GET_PROJECT_TYPES,
      payload: {
        data: data.data,
        filteredData: filteredData,
        page: page,
        hashMap: hashMap,
        pageCount: pageCount,
        totalCount: totalCount,
        error: ''
      }
    })
  } catch (error: unknown) {
    const message: ErrorType = getErrorMessage(error)
    dispatch({
      type: ERROR_MESSAGE,
      payload: (message ? message : '') as string
    })
  }
}

export const filterProjectTypes = async (
  dispatch: Dispatch<TypeContextAction>,
  data: IProjectType[],
  query: string
) => {
  let filteredData = filterData(data, 'name', query)
  filteredData = filteredData ? filteredData : []
  dispatch({
    type: GET_FILTERED_PROJECT_TYPES,
    payload: filteredData
  })
}

export const deleteProjectItem = async (
  dispatch: Dispatch<TypeContextAction>,
  state: IInitialProjectTypeState,
  _id: string,
  page: number
) => {
  const newState = { ...state }
  const index = newState.data.findIndex((el) => el._id === _id)
  newState.data.splice(index, 1)
  if (newState.filteredData) {
    const filterIndex = newState.filteredData.findIndex((el) => el._id === _id)
    newState.data.splice(filterIndex, 1)
  }
  delete newState.hashMap[_id]
  newState.page = page
  newState.totalCount = newState.totalCount - 1
  newState.error = ''
  newState.pageCount = Math.ceil(
    Number(
      (newState.filteredData ? newState.filteredData : newState.data).length /
        10
    )
  )
  dispatch({ type: DELETE_ITEM, payload: newState })
}
// export const projectTypes = async () => {
//   try {
//     const data = await getProjectTypes({ page: 1, perPage: 50 })
//     return { type: GET_FILTERED_PROJECT_TYPES, payload: data }
//   } catch (error: unknown) {
//     const message: ErrorType = getErrorMessage(error)
//     return { type: ERROR_MESSAGE, payload: message }
//   }
// }
