import styled from 'styled-components'

export const Main = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
`

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
`
