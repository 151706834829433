import React, { FC, ReactNode } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    // padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    // padding: theme.spacing(1),
  }
}))

interface ICustomizedDialogs {
  handleClose: () => void
  open: boolean
  title: string
  children: ReactNode
  confirmTitle: string
  onConfirm: () => void
  fullScreen?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  disabled?: boolean
  onConfirmColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
}

const CustomizedDialogs: FC<ICustomizedDialogs> = ({
  handleClose,
  open,
  title,
  children,
  onConfirm,
  confirmTitle,
  fullScreen,
  maxWidth,
  disabled,
  onConfirmColor
}) => {
  return (
    <>
      <BootstrapDialog
        // https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
        disableRestoreFocus
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={!!fullScreen}
        maxWidth={maxWidth ? maxWidth : 'md'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            color="inherit"
            disabled={!!disabled}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            color={onConfirmColor ? onConfirmColor : 'primary'}
            disabled={!!disabled}
          >
            {!!disabled ? (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              confirmTitle
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}

export default CustomizedDialogs
