import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'

export const StyledPaper = styled.div`
  text-align: center;
  padding: 20px;
  width: 450px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #121212;

  @media (max-width: 600px) {
    width: 500px;
  }
  @media (max-width: 480px) {
    width: 300px;
  }
`

export const Title = styled.h3`
  padding: 10px;
  color: rgb(221, 221, 221);
`

export const InputWrapper = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`
