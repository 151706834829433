export enum UserStatusEnum {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DELETED = 'deleted'
}

export enum UserProjectStatus {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export enum UserQuestStatus {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export enum LeaderboardFilter {
  ALL = 'all',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
