import React, { useEffect, useState } from 'react'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { ContainerItem } from 'cellar/src/globalStyles/styles'
import Header from './components/Header'
import { useQueryParams } from '../../hooks'
import CreationForm from './components/CreationForm'
import Creation from './components/Creation'
import { ErrorType, PageType, SetQueryParamValue } from '../../ts-types'
import {
  IListProjectInput,
  IListStepInput,
  IListStepResponse,
  IProject,
  IStep
} from '@repo/types'
import PaginationWrapper from '../../components/PaginationWrapper'
import { getSteps as getStepsApi } from '../../api'
import { getErrorMessage } from '../../utils'
import Body from './components/Body'
import Modals from './components/Modals'

function Quests() {
  const { getQueryParam, setQueryParam, setQueryParams } = useQueryParams()
  const [state, setState] = useState<null | IListStepResponse>(null)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean | string>(false)
  const [questValue, setQuestValue] = useState(getQueryParam('questId') || '')
  const [creation, setCreation] = useState<string | boolean>(
    getQueryParam('isForm') ? (getQueryParam('isForm') as string) : false
  )
  const [query, setQuery] = useState<string>(getQueryParam('stepsQuery') || '')
  const [pageData, setPageData] = useState<PageType>({
    page: Number(getQueryParam('stepsPage')) || 1,
    perPage: Number(getQueryParam('perPage')) || 10
  })

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {}

  const openForm = (message?: string) => {
    if (creation) {
      setCreation(false)
      setQueryParam('isForm', '')
      getSteps(1, 10, '', '')
    } else {
      setCreation('Create')
      setQueryParam('isForm', 'Create')
    }
    if (message) {
      setSuccessMessage(message)
    }
  }

  const onFilter = (name: string, value: string | null) => {
    const changedParamsData: SetQueryParamValue[] = [
      { key: 'stepsPage', value: '1' },
      { key: 'perPage', value: '10' }
    ]
    getSteps(
      1,
      10,
      name === 'stepsQuery' ? (value as string) : query,
      name === 'questId' ? (value ? value : '') : questValue
    )
    if (name === 'stepsQuery') {
      changedParamsData.push({
        key: 'stepsQuery',
        value: value
      } as SetQueryParamValue)
    } else {
      changedParamsData.push({
        key: 'questId',
        value: value
      } as SetQueryParamValue)
    }
    setQueryParams(changedParamsData)
    setPageData({ ...pageData, page: 1 })
  }

  const getSteps = async (
    page?: number,
    perPage?: number,
    queryVal?: string,
    questId?: string,
    cb?: () => void
  ) => {
    setLoading(true)
    try {
      const filtersData: IListStepInput = {
        page: page ? page : pageData.page,
        perPage: perPage ? perPage : pageData.perPage,
        query: queryVal ? queryVal : queryVal === '' ? '' : query,
        questId: questId ? questId : questId === '' ? '' : questValue
      }

      const steps = await getStepsApi(filtersData)
      setState(steps)
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
    } finally {
      if (cb) {
        cb()
      }
      stopLoading()
    }
  }

  const pageChange = (
    e: React.ChangeEvent<unknown> | null,
    page: number,
    fromModal?: boolean
  ) => {
    if (page !== pageData.page || fromModal) {
      setQueryParam('stepsPage', page.toString())
      setLoading(true)
      setPageData({ ...pageData, page: page })
      getSteps(page, pageData.perPage)
    }
  }

  const onAfterDelete = () => {
    if (state?.data.length === 1 && pageData.page !== 1) {
      setQueryParam('stepsPage', (pageData.page - 1).toString())
      getSteps(pageData.page - 1, pageData.perPage)
      setPageData({ ...pageData, page: pageData.page - 1 })
    } else {
      getSteps(pageData.page, pageData.perPage)
    }
  }

  const editStep = (id: string) => {
    setQueryParam('isForm', id)
    setCreation(id)
  }

  useEffect(() => {
    getSteps()
  }, [])

  return creation ? (
    <ContainerItem>
      <Creation data={creation} setCreation={openForm} />
      {/*<CreationForm data={creation} setCreation={openForm} />*/}
    </ContainerItem>
  ) : (
    <FadeTransition>
      <ContainerItem>
        <Header
          setQuery={setQuery}
          query={query}
          setCreation={openForm}
          onFilter={onFilter}
          setQuestValue={setQuestValue}
          questValue={questValue}
        />
        {state && state.data ? (
          <Body
            state={state?.data as IStep[]}
            setDeleteModal={setDeleteModal}
            setCreation={editStep}
          />
        ) : (
          ''
        )}

        {state && state.data ? (
          <div>
            <PaginationWrapper
              count={state?.pageCount}
              page={pageData.page}
              onChange={pageChange}
            />
          </div>
        ) : (
          ''
        )}

        <Modals
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          errorMessage={errorMessage}
          onAfterDelete={onAfterDelete}
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
        />
      </ContainerItem>
    </FadeTransition>
  )
}

export default Quests
