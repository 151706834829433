import React, { FC } from 'react'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { IOffChainFormState } from '../ts-types'

interface IInformationComponent {
  state: IOffChainFormState
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}

const InformationComponent: FC<IInformationComponent> = ({
  state,
  onChange,
  disabled
}) => {
  return (
    <FadeTransition>
      <Grid container size={{ xs: 12 }} spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            fullWidth
            disabled={disabled}
            name="information_title"
            label="Information Title"
            value={state.information_title.value}
            helperText={state.information_title.error}
            error={!!state.information_title.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 8 }}>
          <TextField
            disabled={disabled}
            fullWidth
            multiline
            name="information_content"
            label="Information Content"
            value={state.information_content.value}
            helperText={state.information_content.error}
            error={!!state.information_content.error}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </FadeTransition>
  )
}

export default InformationComponent
