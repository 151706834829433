import { FallbackProps } from 'react-error-boundary'
import Button from '@mui/material/Button'

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <Button
        sx={{ width: '100px' }}
        variant="contained"
        onClick={resetErrorBoundary}
        color="primary"
      >
        Try again
      </Button>
    </div>
  )
}

export default ErrorFallback
