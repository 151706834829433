import styled from 'styled-components'

export const Container = styled.div`
  padding: 9px;
  position: relative;
  height: 36px;
  border-radius: 5px;
  justify-self: center;
  width: 100%;
  display: flex;
  place-content: space-between;
  border: solid 0.5px #e3e3e3;
  margin-top: 10px;
`

export const IconContainer = styled.div`
  border-radius: 50%;
  color: grey;
`

export const ImgItem = styled.img`
  display: block;
  object-fit: cover;
  margin-right: 5px;
`
