import React, { FC } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { IconContainer, ItemContainer } from '../SocialLinks/style'
import { ListItemType } from '../../ts-types'

interface IAddressesListProps {
  links: ListItemType[]
  handleChange: (name: string, newLinks: ListItemType[]) => void
  label: string
  error?: string
  name: string
  disabled?: boolean
}

const AddressesList: FC<IAddressesListProps> = ({
  links,
  handleChange,
  label,
  error,
  name,
  disabled
}) => {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newLinks = [...links]
    newLinks[index] = {
      value: event.target.value,
      error: !event.target.value
    }
    handleChange(name, newLinks)
  }

  const addNewLink = () => {
    const newLinks: ListItemType[] = [...links, { value: '', error: false }]
    handleChange(name, newLinks)
  }

  const onDelete = (index: number) => {
    const newLinks = [...links]
    newLinks.splice(index, 1)
    handleChange(name, newLinks)
  }

  return (
    <>
      <Box sx={{ maxHeight: '250px', overflow: 'scroll', paddingTop: '10px' }}>
        {links.map((link, i: number) => (
          <ItemContainer>
            <TextField
              sx={{
                marginBottom: '10px',
                '& .MuiInputBase-input': {
                  paddingRight: '45px'
                }
              }}
              size="small"
              label={label}
              disabled={!!disabled}
              fullWidth
              error={link.error}
              value={link.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e, i)
              }}
            />
            <IconContainer>
              <DeleteOutlineOutlinedIcon
                color="error"
                cursor="pointer"
                onClick={() => {
                  onDelete(i)
                }}
              />
            </IconContainer>
          </ItemContainer>
        ))}
      </Box>
      <Typography color="error" variant="body2" textAlign="center">
        {error && !links.length ? error : ''}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          disabled={!!disabled}
          sx={{
            marginTop: '5px'
          }}
          size="small"
          variant="outlined"
          onClick={addNewLink}
          color="primary"
        >
          ADD NEW LINK
        </Button>
      </Box>
    </>
  )
}

export default AddressesList
