import React, { Dispatch, FC, useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import Typography from '@mui/material/Typography'
import { IProject, IQuest, QuestStatusEnum } from '@repo/types'
import { ErrorType, ImageListItem, RadioOptions } from '../../../ts-types'
import {
  checkDateIsValid,
  FormStateType,
  getErrorMessage,
  getFileFromUrl,
  isQuestsStateValid,
  projectInitialFields,
  getImagesList,
  questInitialFields,
  validateValue
} from '../../../utils'
import { ICreationForm } from '../ts-types'
import TextField from '@mui/material/TextField'
import CustomDatePicker from '../../../components/MuiDatePicker'
import ProjectSelector from '../../../components/ProjectSelector'
import InputFileUpload from '../../../components/UI/FileUpload'
import MessageSnack from '../../../components/MessageSnack'
import ImagesList from '../../../components/ImagesList'
import { createQuests, updateQuests, updateQuestStatus } from '../../../api'
import RadioBtn from '../../../components/UI/RadioBtn'
import { StatusModalType } from '../ts-types'

interface IStatusChangeModalProps {
  data: StatusModalType
  handleClose: () => void
  afterUpdate: (value: string) => void
}

const StatusChangeModal: FC<IStatusChangeModalProps> = ({
  data,
  handleClose,
  afterUpdate
}) => {
  const [state, setState] = useState<StatusModalType>({ ...data })
  const [error, setErrorMessage] = useState<string | null>('')
  const onConfirm = () => {
    try {
      ;(async () => {
        const { result } = await updateQuestStatus(
          state.id,
          //@ts-ignore
          QuestStatusEnum[state.status]
        )
        if (result) {
          afterUpdate(state.status)
        }
      })()
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
    }
  }

  const getOptions = (): RadioOptions => {
    const options = Object.keys(QuestStatusEnum)
    return options.map((key) => {
      return { label: key, value: key }
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, status: event.target.value })
  }

  return (
    <CustomizedDialogs
      open={!!data}
      maxWidth="sm"
      handleClose={handleClose}
      title="Edit Quest Status"
      confirmTitle="Edit"
      onConfirm={onConfirm}
    >
      <Box sx={{ width: '300px' }}>
        <RadioBtn
          handleChange={handleChange}
          value={state.status}
          label="Status Type"
          name="status"
          options={getOptions()}
        />
        <Box>{error ? <Typography color="error">{error}</Typography> : ''}</Box>
      </Box>
    </CustomizedDialogs>
  )
}

export default StatusChangeModal
