import {
  GET_FILTERED_PROJECT_TYPES,
  GET_PROJECT_TYPES,
  RESET_PROJECT_TYPES,
  DELETE_ITEM,
  SET_ERROR_MESSAGE,
  CREATE_NEW_PROJECT_TYPE,
  UPDATE_PROJECT_TYPE
} from './types'
import { IListProjectTypeResponse, IProjectType } from '@repo/types'
import {
  IInitialProjectTypeState,
  TypeContextAction,
  ListProjectTypeResponseWithClientData,
  ProjectTypeClientData
} from './ts-types'
import { PAGE_CHANGE } from '../users/types'

export const projectTypeReducer = (
  state: IInitialProjectTypeState,
  action: TypeContextAction
): IInitialProjectTypeState => {
  switch (action.type) {
    case RESET_PROJECT_TYPES:
      return {
        ...state,
        filteredData: null,
        page: 1,
        pageCount: Math.ceil(state.data.length / 10),
        totalCount: state.data.length
      }
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload as string
      }
    case GET_PROJECT_TYPES:
      const getProjectPayload = action.payload as IInitialProjectTypeState
      return {
        ...getProjectPayload
      }
    case PAGE_CHANGE:
      return {
        ...state,
        page: action.payload as number
      }
    case GET_FILTERED_PROJECT_TYPES:
      const filteredPayload = action.payload as IProjectType[]
      return {
        ...state,
        filteredData: filteredPayload,
        page: 1,
        totalCount: filteredPayload.length,
        pageCount: filteredPayload.length
          ? Math.ceil(filteredPayload.length / 10)
          : 1
      }
    case CREATE_NEW_PROJECT_TYPE:
      const createNewTypePayload = action.payload as IProjectType
      const newState = { ...state }
      newState.data = [createNewTypePayload, ...state.data]
      newState.hashMap[createNewTypePayload._id as string] =
        createNewTypePayload
      newState.page = 1
      newState.totalCount = newState.data.length
      newState.filteredData = null
      newState.pageCount = Math.ceil(newState.data.length / 10)
      newState.error = ''
      return newState
    case UPDATE_PROJECT_TYPE:
      const updateTypePayload = action.payload as IProjectType
      const cloneState = { ...state }
      cloneState.hashMap[updateTypePayload?._id as string] = updateTypePayload
      const index = state.data.findIndex(
        (el) => el._id === updateTypePayload._id
      )
      if (index > -1) {
        cloneState.data[index] = updateTypePayload
        if (cloneState.filteredData) {
          const filteredIndex = (
            state.filteredData as IProjectType[]
          )?.findIndex((el) => el._id === updateTypePayload._id)
          cloneState.filteredData[filteredIndex] = updateTypePayload
        }
      }
      return cloneState
    case DELETE_ITEM:
      return action.payload as IInitialProjectTypeState
    default:
      return state
  }
}
