import React, { ReactElement, FC, useContext, useState } from 'react'
import Fade from '@mui/material/Fade'
import { AppProvider } from '@toolpad/core/AppProvider'
import { DashboardLayout } from '@toolpad/core/DashboardLayout'
import { useCustomRouter } from 'cellar/src/utils'
import { theme } from 'cellar/src/globalStyles/theme'
import { NAVIGATION, branding } from 'cellar/src/constants'
import { ResetButton } from './styles'
import { AuthContext } from '../../contexts/auth'
import { LOGOUT } from '../../contexts/auth/types'
import ResetPasswordModal from './components/ResetPasswordModal'
import MessageSnack from '../MessageSnack'

interface ILayout {
  children: ReactElement
}

const Layout: FC<ILayout> = ({ children }) => {
  const router = useCustomRouter()
  const [modal, setModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [dispatch, state]: any = useContext(AuthContext)

  const resetPassword = () => {
    setModal(true)
  }

  const showMessage = () => {
    setSuccessMessage('Your password has been successfully changed!')
    setModal(false)
  }

  return (
    <div>
      <Fade in={true} timeout={500}>
        <div>
          <ResetButton
            variant="outlined"
            onClick={resetPassword}
            color="inherit"
          >
            Reset password
          </ResetButton>
          <AppProvider
            navigation={NAVIGATION}
            router={router}
            theme={theme}
            branding={branding}
            session={{
              user: {
                email: state.email
              }
            }}
            authentication={{
              signIn: () => {},
              signOut: () => {
                dispatch({ type: LOGOUT })
              }
            }}
          >
            <DashboardLayout>{children}</DashboardLayout>
            {modal && (
              <ResetPasswordModal
                showSuccessMessage={showMessage}
                handleClose={() => setModal(false)}
              />
            )}
            {successMessage ? (
              <MessageSnack
                onClose={() => {
                  setSuccessMessage('')
                }}
                severity="success"
                message={successMessage}
              />
            ) : (
              ''
            )}
          </AppProvider>
        </div>
      </Fade>
    </div>
  )
}

export default Layout
