import React, { FC, useState } from 'react'
import Box from '@mui/material/Box'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import Typography from '@mui/material/Typography'
import { ProjectStatusEnum } from '@repo/types'
import { ErrorType, RadioOptions } from '../../../ts-types'
import { getErrorMessage } from '../../../utils'
import { updateProjectStatus, updateQuestStatus } from '../../../api'
import RadioBtn from '../../../components/UI/RadioBtn'
import { StatusModalType } from '../ts-types'

interface IStatusChangeModalProps {
  data: StatusModalType
  handleClose: () => void
  afterUpdate: (value: string) => void
}

const StatusChangeModal: FC<IStatusChangeModalProps> = ({
  data,
  handleClose,
  afterUpdate
}) => {
  const [state, setState] = useState<StatusModalType>({ ...data })
  const [error, setErrorMessage] = useState<string | null>('')
  const onConfirm = () => {
    try {
      ;(async () => {
        const { result } = await updateProjectStatus(
          state.id,
          //@ts-ignore
          ProjectStatusEnum[state.status]
        )
        if (result) {
          afterUpdate(state.status)
        }
      })()
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
    }
  }

  const getOptions = (): RadioOptions => {
    const options = Object.keys(ProjectStatusEnum)
    return options.map((key) => {
      return { label: key, value: key }
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, status: event.target.value })
  }

  return (
    <CustomizedDialogs
      open={!!data}
      maxWidth="sm"
      handleClose={handleClose}
      title="Edit Project Status"
      confirmTitle="Edit"
      onConfirm={onConfirm}
    >
      <Box sx={{ width: '300px' }}>
        <RadioBtn
          handleChange={handleChange}
          value={state.status}
          label="Status Type"
          name="status"
          options={getOptions()}
        />
        <Box>{error ? <Typography color="error">{error}</Typography> : ''}</Box>
      </Box>
    </CustomizedDialogs>
  )
}

export default StatusChangeModal
