import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'

interface CustomDatePickerProps {
  value: Dayjs | null
  label?: string
  fullWidth?: boolean
  error?: boolean
  onChange: (newValue: Dayjs | null, name: string) => void
  name: string
  minDate?: Dayjs
  maxDate?: Dayjs
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  label = 'Select Date',
  fullWidth = false,
  error = false,
  onChange,
  name,
  minDate,
  maxDate
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(value: Dayjs | null) => {
          onChange(value, name)
        }}
        slotProps={{
          textField: {
            fullWidth: fullWidth,
            error: error,
            helperText: error ? 'Invalid date' : ''
          }
        }}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
