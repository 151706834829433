export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES'
export const GET_FILTERED_PROJECT_TYPES = 'GET_FILTERED_PROJECT_TYPES'
export const INITIAL_SETUP_USERS = 'INITIAL_SETUP_USERS'
export const NEW_PROJECT_TYPE = 'NEW_PROJECT_TYPE'
export const UPDATE_PROJECT_TYPE = 'UPDATE_PROJECT_TYPE'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const PAGE_CHANGE = 'PAGE_CHANGE'
export const DELETE_ITEM = 'DELETE_ITEM'
export const EDIT_USER = 'EDIT_USER'
export const CREATE_NEW_USER = 'CREATE_NEW_USER'
