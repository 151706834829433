import React, { FC } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { IAdmin } from '@repo/types'
import Actions from 'cellar/src/components/Actions'
import { usersHeadRows } from 'cellar/src/constants'
import TableWrapper from 'cellar/src/components/TableWrapper'
import StringCuter from 'cellar/src/components/StringCuter'

interface IBodyProps {
  state: Array<IAdmin>
  openEditModal: (user: IAdmin) => void
  openDeleteModal: (id: string) => void
}

const Body: FC<IBodyProps> = ({ state, openEditModal, openDeleteModal }) => {
  return (
    <div>
      <TableWrapper headRows={usersHeadRows}>
        {state.map((user: IAdmin) => (
          <TableRow
            key={user._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StringCuter value={user.email} />
            <StringCuter value={user.role} />
            <StringCuter value={user.status} />
            <TableCell>
              <Actions
                deleteDisabled={user.role === 'super-admin'}
                onDelete={() => {
                  if (user.role === 'super-admin') {
                    return
                  }
                  openDeleteModal(user._id as string)
                }}
                onEdit={() => {
                  openEditModal(user)
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableWrapper>
    </div>
  )
}

export default Body
