import React, { FC } from 'react'
import { ErrorType } from 'cellar/src/ts-types'
import MessageSnack from 'cellar/src/components/MessageSnack'
import DeleteModal from 'cellar/src/components/DeleteModal'
import { projectDeleteText } from 'cellar/src/constants'
import { deleteProject } from 'cellar/src/api'
import { getErrorMessage } from 'cellar/src/utils'
import { IProject } from '@repo/types'
import StatusChangeModal from './StatusChangeModal'
import CreateEditForm from './CreateEditForm'
import { StatusModalType } from '../ts-types'

interface IModalProps {
  errorMessage: ErrorType
  setErrorMessage: (message: ErrorType) => void
  successMessage: string
  setSuccessMessage: (message: string) => void
  editModal: boolean | IProject
  setEditModal: (value: boolean | IProject) => void
  deleteModal: boolean | string
  setDeleteModal: (value: boolean | string) => void
  onAfterDelete: () => void
  createdNewProject: () => void
  setStatusModal: (value: boolean | StatusModalType) => void
  statusModal: boolean | StatusModalType
  afterUpdate: (value: string) => void
}

const Modals: FC<IModalProps> = ({
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  editModal,
  setEditModal,
  deleteModal,
  setDeleteModal,
  onAfterDelete,
  createdNewProject,
  setStatusModal,
  statusModal,
  afterUpdate
}) => {
  const afterCreateEdit = () => {
    if (typeof editModal === 'object') {
      setSuccessMessage('New Project successfully created')
    } else {
      setSuccessMessage('New Project successfully created')
    }
    createdNewProject()
  }

  const onDelete = (cb: (value: boolean) => void) => {
    ;(async () => {
      try {
        const { result } = await deleteProject(deleteModal as string)
        if (result) {
          setSuccessMessage('Project successfully deleted')
        }
        setDeleteModal(false)
        onAfterDelete()
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        cb(false)
      }
    })()
  }

  return (
    <>
      {errorMessage ? (
        <MessageSnack
          onClose={() => {
            setErrorMessage(null)
          }}
          severity="error"
          message={errorMessage}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <MessageSnack
          onClose={() => {
            setSuccessMessage('')
          }}
          severity="success"
          message={successMessage}
        />
      ) : (
        ''
      )}

      {editModal && (
        <CreateEditForm
          afterCreateEdit={afterCreateEdit}
          data={editModal}
          handleClose={() => setEditModal(false)}
        />
      )}

      {deleteModal ? (
        <DeleteModal
          open={!!deleteModal}
          onYes={onDelete}
          handleClose={() => setDeleteModal(false)}
          text={projectDeleteText}
        />
      ) : (
        ''
      )}

      {statusModal ? (
        <StatusChangeModal
          data={statusModal as StatusModalType}
          afterUpdate={afterUpdate}
          handleClose={() => {
            setStatusModal(false)
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Modals
