import React, {
  createContext,
  useReducer,
  FC,
  ReactNode,
  Dispatch,
  useEffect
} from 'react'
import { ErrorType } from 'cellar/src/ts-types'
import { getErrorMessage } from 'cellar/src/utils'
import MessageSnack from 'cellar/src/components/MessageSnack'
import { useQueryParams } from 'cellar/src/hooks'
import { projectTypesDefaultValues } from 'cellar/src/constants'
import { IInitialProjectTypeState, TypeContextAction } from './ts-types'
import { projectTypeReducer } from './reducer'
import { SET_ERROR_MESSAGE } from './types'
import { initialProjectTypes } from './actions'

export interface ProjectTypeContextType {
  state: IInitialProjectTypeState
  dispatch: Dispatch<TypeContextAction>
}

export const ProjectTypeContext = createContext<ProjectTypeContextType>({
  state: { ...projectTypesDefaultValues },
  dispatch: () => {}
})

const init = (initialArg: any) => {
  return {
    ...initialArg
  }
}

export const ProjectTypeProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const { getQueryParam } = useQueryParams()
  const [state, dispatch]: [
    IInitialProjectTypeState,
    Dispatch<TypeContextAction>
  ] = useReducer(projectTypeReducer, projectTypesDefaultValues, init)

  useEffect(() => {
    ;(async () => {
      try {
        const query = getQueryParam('typeQuery') || ''
        const page = Number(getQueryParam('typePage')) || 1
        initialProjectTypes(dispatch, query, page)
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: message ? (message as string) : ''
        })
      }
    })()
  }, [])

  return (
    <ProjectTypeContext.Provider value={{ state, dispatch }}>
      {children}
      {state.error && (
        <MessageSnack
          onClose={() => dispatch({ type: SET_ERROR_MESSAGE, payload: '' })}
          severity="error"
          message={state.error}
        />
      )}
    </ProjectTypeContext.Provider>
  )
}
