// queryUtils.ts
import { useSearchParams } from 'react-router-dom'
import { SetQueryParamValue } from '../ts-types'

type QueryParams = {
  getQueryParam: (key: string) => string | null
  setQueryParam: (key: string, value: string | null) => void
  setQueryParams: (values: SetQueryParamValue[]) => void
  deleteQueryParam: (key: string) => void
  getAllQueryParams: () => Record<string, string>
}

export const useQueryParams = (): QueryParams => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getQueryParam = (key: string): string | null => searchParams.get(key)

  const setQueryParam = (key: string, value: string | null): void => {
    const newParams = new URLSearchParams(searchParams)
    if (value) {
      newParams.set(key, value)
    } else {
      newParams.delete(key)
    }
    setSearchParams(newParams)
  }

  const setQueryParams = (values: SetQueryParamValue[]): void => {
    const newParams = new URLSearchParams(searchParams)
    values.forEach((value) => {
      value.value
        ? newParams.set(value.key, value.value)
        : newParams.delete(value.key)
    })

    setSearchParams(newParams)
  }

  const deleteQueryParam = (key: string): void => {
    const newParams = new URLSearchParams(searchParams)
    newParams.delete(key)
    setSearchParams(newParams)
  }

  const getAllQueryParams = (): Record<string, string> => {
    return Object.fromEntries(searchParams.entries())
  }

  return {
    getQueryParam,
    setQueryParam,
    setQueryParams,
    deleteQueryParam,
    getAllQueryParams
  }
}
