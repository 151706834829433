import {
  CREATE_NEW_USER,
  DELETE_ITEM,
  EDIT_USER,
  GET_PROJECT_TYPES,
  INITIAL_SETUP_USERS,
  NEW_PROJECT_TYPE,
  PAGE_CHANGE,
  SET_ERROR_MESSAGE,
  UPDATE_PROJECT_TYPE
} from './types'
import {
  IAdmin,
  IListAdminResponse,
  IListProjectTypeResponse,
  IProjectType
} from '@repo/types'
import {
  TypeContextAction,
  ListProjectTypeResponseWithClientData,
  ProjectTypeClientData,
  UserInitialSetupData,
  IInitialUserState,
  DeleteData
} from './ts-types'

export const userReducer = (
  state: IInitialUserState,
  action: TypeContextAction
): IInitialUserState => {
  switch (action.type) {
    case INITIAL_SETUP_USERS:
      const initialPayload = action.payload as UserInitialSetupData
      return {
        data: initialPayload.data.data,
        filterData: initialPayload.filterData,
        page: Number(initialPayload.data.page),
        hashMap: initialPayload.hashMap,
        pageCount: Math.ceil(
          Number(
            initialPayload.filterData
              ? initialPayload.filterData
              : initialPayload.data.totalCount
          ) / 10
        ),
        totalCount: Number(initialPayload.data.totalCount),
        error: ''
      }
    case PAGE_CHANGE:
      const newPage = action.payload
      return {
        ...state,
        page: newPage
      }
    case DELETE_ITEM:
      const deletePayload = action.payload as DeleteData
      const newState = { ...state }
      newState.page = deletePayload.page
      const index = state.data.findIndex((el) => el._id === deletePayload.id)
      newState.data.splice(index, 1)
      delete newState.hashMap[deletePayload.id]
      newState.totalCount = newState.totalCount - 1
      newState.pageCount = Math.ceil(newState.data.length / 10)
      return newState
    case EDIT_USER:
      return state
    case CREATE_NEW_USER:
      const cloneState = { ...state }
      cloneState.data = [action.payload, ...state.data]
      cloneState.page = 1
      cloneState.hashMap[action.payload._id] = action.payload
      cloneState.totalCount = cloneState.totalCount + 1
      cloneState.pageCount = Math.ceil(cloneState.totalCount / 10)
      return cloneState
    // case SET_ERROR_MESSAGE:
    //   return {
    //     ...state,
    //     error: action.payload as string
    //   }
    // case GET_PROJECT_TYPES:
    //   const getProjectPayload = action.payload as IListProjectTypeResponse
    //   return {
    //     data: getProjectPayload.data,
    //     page: Number(getProjectPayload.page),
    //     filteredData: false,
    //     totalCount: getProjectPayload.totalCount,
    //     pageCount: getProjectPayload.pageCount,
    //     error: ''
    //   }
    // case GET_FILTERED_PROJECT_TYPES:
    //   const getFilterProjectPayload = action.payload as IListProjectTypeResponse
    //   return {
    //     data: getFilterProjectPayload.data,
    //     page: Number(getFilterProjectPayload.page),
    //     filteredData: true,
    //     totalCount: getFilterProjectPayload.totalCount,
    //     pageCount: getFilterProjectPayload.pageCount,
    //     error: (state as IInitialProjectTypeState).error
    //   }
    // case NEW_PROJECT_TYPE:
    //   const getNewProjectType =
    //     action.payload as ListProjectTypeResponseWithClientData
    //   return {
    //     data: getNewProjectType.data,
    //     page: Number(getNewProjectType.page),
    //     filteredData: getNewProjectType.filteredData,
    //     totalCount: getNewProjectType.totalCount,
    //     pageCount: getNewProjectType.pageCount,
    //     error: (state as IInitialProjectTypeState).error
    //   }
    // case UPDATE_PROJECT_TYPE:
    //   const updateProjectTypePayload = action.payload as ProjectTypeClientData
    //   const newState = {
    //     ...(state as IInitialProjectTypeState),
    //     data: [...(state as IInitialProjectTypeState).data]
    //   }
    //   newState.data[updateProjectTypePayload.index] =
    //     updateProjectTypePayload.item
    //   newState.filteredData = updateProjectTypePayload.filteredData
    //   return newState
    default:
      return state
  }
}
