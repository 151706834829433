import React, { FC } from 'react'
import { ErrorType } from 'cellar/src/ts-types'
import MessageSnack from 'cellar/src/components/MessageSnack'
import DeleteModal from 'cellar/src/components/DeleteModal'
import { projectDeleteText, stepDeleteText } from 'cellar/src/constants'
import { deleteProject, deleteStep } from 'cellar/src/api'
import { getErrorMessage } from 'cellar/src/utils'
import { IProject } from '@repo/types'
// import StatusChangeModal from './StatusChangeModal'
// import CreateEditForm from './CreateEditForm'
// import { StatusModalType } from '../ts-types'

interface IModalProps {
  errorMessage: ErrorType
  setErrorMessage: (message: ErrorType) => void
  successMessage: string
  setSuccessMessage: (message: string) => void
  deleteModal: boolean | string
  setDeleteModal: (value: boolean | string) => void
  onAfterDelete: () => void

  // editModal: boolean | IProject
  // setEditModal: (value: boolean | IProject) => void
  // createdNewProject: () => void
  // setStatusModal: (value: boolean | StatusModalType) => void
  // statusModal: boolean | StatusModalType
  // afterUpdate: (value: string) => void
}

const Modals: FC<IModalProps> = ({
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  deleteModal,
  setDeleteModal,
  onAfterDelete

  // editModal,
  // setEditModal,
  // createdNewProject,
  // setStatusModal,
  // statusModal,
  // afterUpdate
}) => {
  // const afterCreateEdit = () => {
  //   if (typeof editModal === 'object') {
  //     setSuccessMessage('New Project successfully created')
  //   } else {
  //     setSuccessMessage('New Project successfully created')
  //   }
  //   createdNewProject()
  // }
  //
  const onDelete = (cb: (value: boolean) => void) => {
    ;(async () => {
      try {
        const { result } = await deleteStep(deleteModal as string)
        if (result) {
          setSuccessMessage('Project successfully deleted')
        }
        setDeleteModal(false)
        onAfterDelete()
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        cb(false)
      }
    })()
  }

  return (
    <>
      {errorMessage ? (
        <MessageSnack
          onClose={() => {
            setErrorMessage(null)
          }}
          severity="error"
          message={errorMessage}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <MessageSnack
          onClose={() => {
            setSuccessMessage('')
          }}
          severity="success"
          message={successMessage}
        />
      ) : (
        ''
      )}

      {deleteModal ? (
        <DeleteModal
          open={!!deleteModal}
          onYes={onDelete}
          handleClose={() => setDeleteModal(false)}
          text={stepDeleteText}
        />
      ) : (
        ''
      )}

      {/*{editModal && (*/}
      {/*  <CreateEditForm*/}
      {/*    afterCreateEdit={afterCreateEdit}*/}
      {/*    data={editModal}*/}
      {/*    handleClose={() => setEditModal(false)}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{statusModal ? (*/}
      {/*  <StatusChangeModal*/}
      {/*    data={statusModal as StatusModalType}*/}
      {/*    afterUpdate={afterUpdate}*/}
      {/*    handleClose={() => {*/}
      {/*      setStatusModal(false)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  ''*/}
      {/*)}*/}
    </>
  )
}

export default Modals
