import React, { useEffect, useState } from 'react'
import {
  IListProjectInput,
  IListProjectResponse,
  IProject,
  ProjectStatusEnum
} from '@repo/types'
import { ContainerItem, LoadingWrapper } from 'cellar/src/globalStyles/styles'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { getProjects as getProjectsApi } from 'cellar/src/api'
import { ErrorType, PageType, SetQueryParamValue } from 'cellar/src/ts-types'
import { getErrorMessage } from 'cellar/src/utils'
import { useQueryParams } from 'cellar/src/hooks'

import Header from './components/Header'
import Body from './components/Body'
import LinearProgress from '@mui/material/LinearProgress'
import PaginationWrapper from '../../components/PaginationWrapper'
import Modals from './components/Modals'
import { StatusModalType } from './ts-types'

function Projects() {
  const { getQueryParam, setQueryParam, setQueryParams } = useQueryParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean | string>(false)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [state, setState] = useState<IListProjectResponse | null>(null)
  const [editModal, setEditModal] = useState<boolean | IProject>(false)
  const [statusModal, setStatusModal] = useState<boolean | StatusModalType>(
    false
  )
  const [queryValue, setQueryValue] = useState<string>(
    getQueryParam('query') || ''
  )
  const [projectTypeValue, setProjectTypeValue] = useState(
    getQueryParam('typeId') || ''
  )
  const [pageData, setPageData] = useState<PageType>({
    page: Number(getQueryParam('page')) || 1,
    perPage: Number(getQueryParam('perPage')) || 10
  })

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getProjects = async (
    page?: number,
    perPage?: number,
    query?: string,
    typeId?: string,
    cb?: () => void
  ) => {
    setLoading(true)
    try {
      const filtersData: IListProjectInput = {
        page: page ? page : pageData.page,
        perPage: perPage ? perPage : pageData.perPage,
        query: query ? query : query === '' ? '' : queryValue,
        typeId: typeId ? typeId : typeId === '' ? '' : projectTypeValue
      }

      const projects = await getProjectsApi(filtersData)
      setState(projects)
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
    } finally {
      if (cb) {
        cb()
      }
      stopLoading()
    }
  }

  const pageChange = (
    e: React.ChangeEvent<unknown> | null,
    page: number,
    fromModal?: boolean
  ) => {
    if (page !== pageData.page || fromModal) {
      setQueryParam('page', page.toString())
      setLoading(true)
      setPageData({ ...pageData, page: page })
      getProjects(page, pageData.perPage)
    }
  }

  const openDeleteModal = (id: string) => {
    setDeleteModal(id)
  }

  const openEditModal = (project: IProject) => {
    setEditModal(project)
  }

  const onFilter = (name: string, value: string | null) => {
    const changedParamsData: SetQueryParamValue[] = [
      { key: 'page', value: '1' },
      { key: 'perPage', value: '10' }
    ]
    getProjects(
      1,
      10,
      name === 'query' ? (value as string) : queryValue,
      name === 'typeId' ? (value ? value : '') : projectTypeValue
    )
    if (name === 'query') {
      setQueryParam('query', value)
      changedParamsData.push({
        key: 'query',
        value: value
      } as SetQueryParamValue)
    } else {
      changedParamsData.push({
        key: 'typeId',
        value: value
      } as SetQueryParamValue)
    }
    setQueryParams(changedParamsData)
    setPageData({ ...pageData, page: 1 })
  }

  const createdNewProject = () => {
    setQueryParams([{ key: 'page', value: '1' }])
    setEditModal(false)
    setPageData({ page: 1, perPage: 10 })
    getProjects(1, 10)
  }

  const onAfterDelete = () => {
    if (state?.data.length === 1 && pageData.page != 1) {
      setQueryParam('page', (pageData.page - 1).toString())
      getProjects(pageData.page - 1, pageData.perPage)
      setPageData({ ...pageData, page: pageData.page - 1 })
    } else {
      getProjects(pageData.page, pageData.perPage)
    }
  }

  const afterStatusUpdate = (value: string) => {
    setSuccessMessage('Status has been successfully changed!')
    if (statusModal && state?.data) {
      const index = state?.data.findIndex(
        (el) => el._id === (statusModal as StatusModalType).id
      )
      if (index > -1) {
        const newState = { ...state }
        newState.data[index].status = value as ProjectStatusEnum
        setState(newState)
        setStatusModal(false)
      }
    }
  }

  useEffect(() => {
    getProjects()
  }, [])

  return (
    <>
      <FadeTransition>
        <ContainerItem>
          <Header
            setProjectTypeValue={setProjectTypeValue}
            onFilter={onFilter}
            projectTypeValue={projectTypeValue}
            queryValue={queryValue}
            setQueryValue={setQueryValue}
            setEditModal={setEditModal}
          />
          <LoadingWrapper>
            {!state || loading ? <LinearProgress /> : ''}
          </LoadingWrapper>

          {state && state.data ? (
            <Body
              setStatusModal={setStatusModal}
              state={state?.data as IProject[]}
              openDeleteModal={openDeleteModal}
              openEditModal={openEditModal}
            />
          ) : (
            ''
          )}

          {state && state.data ? (
            <div>
              <PaginationWrapper
                count={state?.pageCount}
                page={pageData.page}
                onChange={pageChange}
              />
            </div>
          ) : (
            ''
          )}

          <Modals
            createdNewProject={createdNewProject}
            onAfterDelete={onAfterDelete}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            editModal={editModal}
            setEditModal={setEditModal}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            setStatusModal={setStatusModal}
            statusModal={statusModal}
            afterUpdate={afterStatusUpdate}
          />
        </ContainerItem>
      </FadeTransition>
    </>
  )
}

export default Projects
