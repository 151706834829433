import React, { useState, FC } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { TextFieldProps } from '@mui/material/TextField'
import TextField from '@mui/material/TextField'
import { ShowIcon, Container } from './style'

interface IPasswordInput
  extends Omit<
    TextFieldProps,
    'name' | 'label' | 'error' | 'onChange' | 'value'
  > {
  error?: boolean | string
  value: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
}

const PasswordInput: FC<IPasswordInput> = ({
  error,
  onChange,
  value,
  name,
  label = 'Password',
  ...otherProps
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  // @ts-ignore
  return (
    <Container>
      <TextField
        fullWidth
        label={label}
        value={value}
        name={name}
        error={!!error}
        helperText={error}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        {...otherProps}
      />
      <ShowIcon onClick={handleClickShowPassword}>
        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </ShowIcon>
    </Container>
  )
}

export default PasswordInput
