import React, { createContext, useReducer, FC, ReactNode } from 'react'
import { UserState } from 'cellar/src/ts-types'
import { yoQuestEmail, storeTokenName } from 'cellar/src/constants'
import { getItem } from 'cellar/src/utils'
import { authReducer } from './reducer'

const email = localStorage.getItem(yoQuestEmail)
const token = getItem(storeTokenName)

const initialAuthState: UserState = {
  email: email ? email : null,
  isAuthenticated: !!token
}

export const AuthContext = createContext<any>(initialAuthState)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  //@ts-ignore
  const [state, dispatch] = useReducer(authReducer, initialAuthState)

  return (
    <AuthContext.Provider value={[dispatch, state]}>
      {children}
    </AuthContext.Provider>
  )
}
