import React, { FC, useEffect, useRef } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  Header as HeaderContainer,
  SearchBlock
} from 'cellar/src/globalStyles/styles'
import Search from 'cellar/src/components/Search'
import ProjectTypeSelector from 'cellar/src/components/ProjectTypeSelector'

interface IHeaderProps {
  queryValue: string
  setQueryValue: (value: string) => void
  onFilter: (name: string, value: string | null) => void
  setEditModal: (value: boolean) => void
  projectTypeValue: string
  setProjectTypeValue: (value: string) => void
}

const Header: FC<IHeaderProps> = ({
  queryValue,
  setQueryValue,
  onFilter,
  setEditModal,
  projectTypeValue,
  setProjectTypeValue
}) => {
  const isFirstRender = useRef(true)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const onSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setQueryValue(target.value)
  }

  const onAutocompleteTypeChange = (
    event: React.SyntheticEvent,
    value: string
  ) => {
    onFilter('typeId', value)
    setProjectTypeValue(value ? value : '')
  }

  useEffect(() => {
    if (!isFirstRender.current) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        onFilter('query', queryValue)
      }, 500)
    } else {
      isFirstRender.current = false
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [queryValue])

  return (
    <>
      <HeaderContainer>
        <Box display="flex">
          <SearchBlock>
            <Search value={queryValue} onChange={onSearch} />
          </SearchBlock>
          <Box sx={{ width: '200px' }}>
            <ProjectTypeSelector
              size="small"
              defaultValue={projectTypeValue}
              error={false}
              onInputChange={onAutocompleteTypeChange}
              name="typeId"
            />
          </Box>
        </Box>

        <Typography component="h5">PROJECTS</Typography>

        <Button
          size="small"
          sx={{ width: '150px' }}
          variant="contained"
          onClick={() => setEditModal(true)}
        >
          <Typography variant="body2">CREATE</Typography>
        </Button>
      </HeaderContainer>
    </>
  )
}

export default Header
