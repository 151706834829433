import styled from 'styled-components'

export const ItemContainer = styled.div`
  position: relative;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`
