import { FormItem } from 'cellar/src/ts-types'
import { ProjectTypeEnum } from '@repo/types'

export enum ProjectType {
  Bridge = 'Bridge',
  DeFi = 'DeFi',
  NFT = 'NFT',
  Games = 'Games',
  Social = 'Social',
  AI = 'AI'
}

export interface IProject {
  name: string
  logo: string
  description: string
  projectType: ProjectType
  website: string
  socialLinks?: string[]
}

export interface IForm {
  name: FormItem
  logo: {
    value: File | string
    error: string | boolean
    touched: boolean
  }
  description: FormItem
  projectType: FormItem
  website: FormItem
  socialLinks: string[]
}

export type FilterStateType = {
  query: string
  typeId: ProjectTypeEnum | null | any
}

export type StatusModalType = {
  status: string
  id: string
}
