import React, { FC } from 'react'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import { IconWrapper, Main } from './styles'

interface ISearch {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Search: FC<ISearch> = ({ onChange, value }) => {
  return (
    <Main>
      <TextField
        onChange={onChange}
        id="outlined-basic"
        fullWidth={true}
        label="Search"
        variant="outlined"
        size="small"
        value={value}
      />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </Main>
  )
}

export default Search
