import React, { useState, useContext } from 'react'
import { IAdmin } from '@repo/types'
import FadeTransition from 'cellar/src/components/FadeTransition'
import PaginationWrapper from 'cellar/src/components/PaginationWrapper'
import { ContainerItem } from 'cellar/src/globalStyles/styles'
import { ErrorType } from 'cellar/src/ts-types'
import { useQueryParams } from 'cellar/src/hooks'
import { UserContext } from 'cellar/src/contexts/users'
import {
  PAGE_CHANGE,
  DELETE_ITEM,
  EDIT_USER,
  CREATE_NEW_USER
} from 'cellar/src/contexts/users/types'
import Body from './components/Body'
import { EditCreateFormModalType } from './ts-types'
import Header from './components/Header'
import Modals from './components/Modals'

function Users() {
  const { setQueryParam } = useQueryParams()
  const { state, dispatch } = useContext(UserContext)
  const getData = () => {
    const data = state && (state.filterData ? state.filterData : state.data)
    const sliceFrom = (state.page - 1) * 10
    const sliceTo = state.page * 10
    return data.slice(sliceFrom, sliceTo)
  }
  const [search, setSearch] = useState<string>('')
  const [deleteModal, setDeleteModal] = useState<boolean | string>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [editModal, setEditModal] = useState<EditCreateFormModalType>(false)

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }
  const openEditModal = (user: IAdmin) => {
    setEditModal(user)
  }

  const openDeleteModal = (id: string) => {
    setDeleteModal(id)
  }

  const pageChange = (e: React.ChangeEvent<unknown> | null, page: number) => {
    if (page !== state.page) {
      dispatch({ type: PAGE_CHANGE, payload: page })
      setQueryParam('userPage', page.toString())
    }
  }

  const onAfterDelete = () => {
    if (getData().length === 1 && state.page !== 1) {
      setQueryParam('userPage', (state.page - 1).toString())
    }
    dispatch({
      type: DELETE_ITEM,
      payload: {
        id: deleteModal,
        page:
          getData().length === 1 && state.page !== 1
            ? state.page - 1
            : state.page
      }
    })
  }

  const onAfterCreateEdit = (newData: any, isEdit: boolean) => {
    setQueryParam('userPage', '1')
    dispatch({
      type: isEdit ? EDIT_USER : CREATE_NEW_USER,
      payload: isEdit
        ? {
            ...(editModal as IAdmin),
            newData
          }
        : { ...newData }
    })
  }

  return (
    <FadeTransition>
      <ContainerItem>
        <Header
          search={search}
          onSearch={onSearch}
          setEditModal={setEditModal}
        />

        {getData() ? (
          <Body
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
            state={getData()}
          />
        ) : (
          ''
        )}
        <div>
          <PaginationWrapper
            count={state?.pageCount}
            page={state.page}
            onChange={pageChange}
          />
        </div>

        <Modals
          onAfterCreateEdit={onAfterCreateEdit}
          onAfterDelete={onAfterDelete}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          editModal={editModal}
          setEditModal={setEditModal}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      </ContainerItem>
    </FadeTransition>
  )
}

export default Users
