import React, { FC } from 'react'
import { ErrorType } from 'cellar/src/ts-types'
import MessageSnack from 'cellar/src/components/MessageSnack'
import DeleteModal from 'cellar/src/components/DeleteModal'
import { adminDeleteText } from 'cellar/src/constants'
import { deleteAdmin } from 'cellar/src/api'
import { getErrorMessage } from 'cellar/src/utils'
import CreateEditForm from './CreateEditForm'
import { EditCreateFormModalType } from '../ts-types'

interface IModalProps {
  errorMessage: ErrorType
  setErrorMessage: (message: ErrorType) => void
  successMessage: string
  setSuccessMessage: (message: string) => void
  editModal: EditCreateFormModalType
  setEditModal: (value: EditCreateFormModalType) => void
  deleteModal: boolean | string
  setDeleteModal: (value: boolean | string) => void
  onAfterDelete: () => void
  onAfterCreateEdit: (newData: any, isEdit: boolean) => void
}

const Modals: FC<IModalProps> = ({
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  editModal,
  setEditModal,
  deleteModal,
  setDeleteModal,
  onAfterDelete,
  onAfterCreateEdit
}) => {
  const afterCreateEdit = (newData: any) => {
    if (typeof editModal === 'object') {
      setSuccessMessage('New user successfully created')
      onAfterCreateEdit(newData, true)
    } else {
      setSuccessMessage('New user successfully created')
      onAfterCreateEdit(newData, false)
    }
  }

  const onDelete = (cb: (value: boolean) => void) => {
    ;(async () => {
      try {
        const { result } = await deleteAdmin(deleteModal as string)
        if (result) {
          setSuccessMessage('User successfully deleted')
        }
        setDeleteModal(false)
        onAfterDelete()
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        cb(false)
      }
    })()
  }

  return (
    <>
      {errorMessage ? (
        <MessageSnack
          onClose={() => {
            setErrorMessage(null)
          }}
          severity="error"
          message={errorMessage}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <MessageSnack
          onClose={() => {
            setSuccessMessage('')
          }}
          severity="success"
          message={successMessage}
        />
      ) : (
        ''
      )}

      {editModal ? (
        <CreateEditForm
          open={!!editModal}
          handleClose={() => setEditModal(false)}
          fields={typeof editModal === 'object' ? editModal : undefined}
          afterCreateEdit={afterCreateEdit}
          title={
            typeof editModal === 'object' ? 'Edit user' : 'Create new user'
          }
        />
      ) : (
        ''
      )}

      {deleteModal ? (
        <DeleteModal
          open={!!deleteModal}
          onYes={onDelete}
          handleClose={() => setDeleteModal(false)}
          text={adminDeleteText}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Modals
