import React, { FC } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { IconContainer, ItemContainer } from './style'
import Typography from '@mui/material/Typography'
import { SocialLinkItemType } from '../../ts-types'
import { isWebSite } from '../../utils'

interface ISocialLinksProps {
  links: SocialLinkItemType[]
  handleChange: (newLinks: SocialLinkItemType[]) => void
  error?: boolean | string
}

const SocialLinks: FC<ISocialLinksProps> = ({ links, handleChange, error }) => {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newLinks = [...links]
    const validLink = isWebSite(event.target.value)


    newLinks[index] = {
      value: event.target.value,
      error: typeof validLink === 'string' ? validLink : ''
    }
    handleChange(newLinks)
  }

  const addNewLink = () => {
    const newLinks: SocialLinkItemType[] = [...links, { value: '', error: '' }]
    handleChange(newLinks)
  }

  const onDelete = (index: number) => {
    const newLinks = [...links]
    newLinks.splice(index, 1)
    handleChange(newLinks)
  }

  return (
    <>
      <Box sx={{ maxHeight: '250px', overflow: 'scroll', paddingTop: '10px' }}>
        {links.map((link, i: number) => (
          <ItemContainer>
            <TextField
              sx={{
                marginBottom: '10px',
                '& .MuiInputBase-input': {
                  paddingRight: '45px'
                }
              }}
              size="small"
              label="Social Link"
              fullWidth
              error={!!link.error}
              helperText={link.error ? link.error : ''}
              value={link.value ? link.value : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e, i)
              }}
            />
            <IconContainer>
              <DeleteOutlineOutlinedIcon
                color="error"
                cursor="pointer"
                onClick={() => {
                  onDelete(i)
                }}
              />
            </IconContainer>
          </ItemContainer>
        ))}
      </Box>
      <Typography color="error" variant="body2" textAlign="center">
        {error ? error : ''}
      </Typography>
      {links.length < 6 && (
        <Box sx={{ textAlign: 'center' }}>
          <Button
            sx={{
              marginTop: '5px'
            }}
            size="small"
            variant="outlined"
            onClick={addNewLink}
            color="primary"
          >
            Add new social link
          </Button>
        </Box>
      )}
    </>
  )
}

export default SocialLinks
