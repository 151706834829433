import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Fade from '@mui/material/Fade'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { LOGIN } from 'cellar/src/contexts/auth/types'
import { deepClone, getErrorMessage, isEmail, setItem } from 'cellar/src/utils'
import { useToggle } from 'cellar/src/hooks'
import PasswordInput from 'cellar/src/components/PasswordInput'
import { FormItem, ErrorType } from 'cellar/src/ts-types'
import { login } from 'cellar/src/api'
import {
  formField,
  storeTokenName,
  validEmailMessage,
  yoQuestEmail
} from 'cellar/src/constants'
import { AuthContext } from 'cellar/src/contexts/auth'
import { AuthState } from './ts-types'
import { StyledPaper, Title, InputWrapper } from './styles'

function Login() {
  const navigate = useNavigate()
  const [isLoading, toggle] = useToggle(false)
  const [dispatch]: any = useContext(AuthContext)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [state, setState] = useState<AuthState>({
    email: { ...formField },
    password: { ...formField }
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.target
    const newState: AuthState = deepClone<AuthState>(state)
    // @ts-ignore
    ;(newState[name] as FormItem).value = value
    if (value) {
      // @ts-ignore
      ;(newState[name] as FormItem).error = false
    }
    // @ts-ignore
    ;(newState[name] as FormItem).touched = true
    setState(newState)
  }

  const onLogin = () => {
    toggle()
    const email = state.email.value
    const password = state.password.value

    if (!email || !password) {
      setState({
        email: {
          value: email,
          error: !email,
          touched: true
        },
        password: {
          value: password,
          error: !password,
          touched: true
        }
      })
      toggle()
    } else {
      if (!isEmail(email)) {
        setState({
          ...state,
          email: { ...state.email, error: validEmailMessage }
        })
        toggle()
        return
      }

      ;(async () => {
        try {
          const userData = await login({ email, password })
          if (userData?.token) {
            setItem(storeTokenName, userData.token)
            setItem(yoQuestEmail, email)
            dispatch({ type: LOGIN, payload: { email: email } })
            navigate('/')
          }
        } catch (error: unknown) {
          const message: ErrorType = getErrorMessage(error)
          setErrorMessage(message)
        } finally {
          toggle()
        }
      })()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onLogin()
    }
  }

  return (
    <div>
      <Fade in={true} timeout={500}>
        <StyledPaper>
          <Title>Log In</Title>

          <InputWrapper>
            <TextField
              fullWidth
              autoFocus
              label="Email"
              name="email"
              value={state.email.value}
              error={!!state.email.error}
              onChange={onChange}
            />
          </InputWrapper>

          <InputWrapper>
            <PasswordInput
              value={state.password.value}
              name="password"
              error={!!state.password.error}
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </InputWrapper>

          {typeof state.email.error === 'string' && (
            <InputWrapper>
              <Typography color="error">{state.email.error}</Typography>
            </InputWrapper>
          )}

          {errorMessage ? (
            <InputWrapper>
              <Typography color="error">{errorMessage}</Typography>
            </InputWrapper>
          ) : (
            ''
          )}

          <Button
            sx={{ width: '100px' }}
            disabled={isLoading}
            variant="contained"
            onClick={onLogin}
            color="primary"
          >
            {isLoading ? <CircularProgress size={25} /> : 'Log In'}
          </Button>
        </StyledPaper>
      </Fade>
    </div>
  )
}

export default Login
