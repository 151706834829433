import React, { FC } from 'react'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Container, DeleteIconWrapper } from './styles'

interface IActions {
  onDelete: () => void
  onEdit: () => void
  deleteDisabled?: boolean
  editDisabled?: boolean
  onShow?: () => void
}

const Actions: FC<IActions> = ({
  onDelete,
  onEdit,
  deleteDisabled,
  onShow,
  editDisabled
}) => {
  return (
    <Container>
      <DeleteIconWrapper>
        {deleteDisabled ? (
          <LockOutlinedIcon />
        ) : (
          <DeleteIcon color="error" onClick={onDelete} cursor="pointer" />
        )}
      </DeleteIconWrapper>
      <div>
        {editDisabled ? (
          ''
        ) : (
          <EditIcon onClick={onEdit} color="inherit" cursor="pointer" />
        )}
      </div>
      {onShow ? (
        <Box sx={{ marginLeft: '20px' }}>
          <VisibilityOutlinedIcon
            onClick={onShow}
            color="inherit"
            cursor="pointer"
          />
        </Box>
      ) : (
        ''
      )}
    </Container>
  )
}

export default Actions
