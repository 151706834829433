import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import UploadImg from 'cellar/public/upload-img.svg'
import FormHelperText from '@mui/material/FormHelperText'
import { Simulate } from 'react-dom/test-utils'
import FastLogo from '../../../../public/FastLogo.svg'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

interface IInputFileUpload {
  accept?: string
  multiple?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  title?: string
}

const InputFileUpload: FC<IInputFileUpload> = ({
  multiple = true,
  onChange,
  error,
  title
}) => {
  return (
    <Box sx={{ justifyItems: 'center' }}>
      <Button
        sx={{
          height: '108px',
          fontSize: '10px',
          width: '100%',
          display: 'block',
          placeItems: 'center',
          background: 'rgba(255,247,250,0.74)',
          borderStyle: 'dashed'
        }}
        component="label"
        role={undefined}
        fullWidth
        variant="outlined"
        tabIndex={-1}
        color={error ? 'error' : 'primary'}
        // startIcon={<CloudUploadIcon />}
      >
        <img
          className="ml-4"
          src={UploadImg}
          width={40}
          height={40}
          alt="logo"
        />
        {title ? (
          title
        ) : (
          <>
            <Box sx={{ textAlign: 'center' }}>
              Supported formats: JPEG, PNG, WEBP,
            </Box>
            <Box sx={{ textAlign: 'center' }}> and SVG.</Box>
          </>
        )}
        <VisuallyHiddenInput
          // accept={accept}
          type="file"
          id="logo"
          name="logo"
          accept="image/*"
          onChange={onChange}
          multiple={multiple}
        />
      </Button>
      {/*<FormHelperText sx={{ color: 'red' }}>*/}
      {/*  {error ? error : ''}*/}
      {/*</FormHelperText>*/}
    </Box>
  )
}

export default InputFileUpload
