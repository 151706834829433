import React, { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

interface ILoading {
  size?: number
}

const Loading: FC<ILoading> = ({ size }) => {
  return (
    <Box sx={{ textAlign: 'center', paddingTop: '20%' }}>
      <CircularProgress size={size ? size : 50} />
    </Box>
  )
}

export default Loading
