import React, { useState, FC } from 'react'
import Box from '@mui/material/Box'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { ContainerItem } from 'cellar/src/globalStyles/styles'
import TextField from '@mui/material/TextField'
import { ErrorType, FormItem } from '../../../ts-types'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import { createProjectTypes, updateProjectTypes } from '../../../api'
import { getErrorMessage } from '../../../utils'
import Typography from '@mui/material/Typography'
import { IProjectType } from '@repo/types'
import { EditDataType } from '../ts-types'

interface ICreateEditFormProps {
  data: EditDataType | boolean
  handleClose: () => void
  onYes: (value: IProjectType) => void
}

const CreateEditForm: FC<ICreateEditFormProps> = ({
  data,
  handleClose,
  onYes
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<FormItem>({
    value: typeof data === 'object' ? (data as EditDataType).data.name : '',
    error: false,
    touched: false
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
      touched: true
    })
  }

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const onConfirm = () => {
    if (name.value) {
      ;(async () => {
        try {
          const result = await (typeof data === 'object'
            ? updateProjectTypes(
                name.value,
                (data as EditDataType)?.data?._id as string
              )
            : createProjectTypes(name.value))
          onYes(result)
        } catch (error: unknown) {
          const message: ErrorType = getErrorMessage(error)
          setErrorMessage(message)
        } finally {
          stopLoading()
        }
      })()
    } else {
      setName({ value: '', error: true, touched: true })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onConfirm()
    }
  }

  return (
    <CustomizedDialogs
      open={!!data}
      maxWidth="sm"
      handleClose={handleClose}
      disabled={loading}
      title={typeof data === 'object' ? 'EDIT PROJECT' : 'CREATE PROJECT TYPE'}
      confirmTitle="Yes"
      onConfirm={onConfirm}
    >
      <Box sx={{ minWidth: '300px' }}>
        <TextField
          autoFocus
          fullWidth
          label="Name"
          name="name"
          onKeyDown={handleKeyDown}
          value={name.value}
          error={!!name.error}
          onChange={onChange}
        />
        {errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : (
          ''
        )}
      </Box>
    </CustomizedDialogs>
  )
}

export default CreateEditForm
