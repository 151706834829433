import { FC, ReactNode } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material'

import BasicTooltip from '../UI/BasicTooltip'

type rowItem = {
  value: string
  sx: SxProps<Theme>
}

interface ILayout {
  headRows: (string | rowItem)[]
  children: ReactNode
}

const TableWrapper: FC<ILayout> = ({ headRows, children }) => {
  const getItem = (item: string | rowItem, index: number) => {
    if (typeof item === 'string') {
      return item.length > 30 ? (
        <BasicTooltip title={item}>
          <TableCell key={item + index}>{item.slice(0, 30) + '...'}</TableCell>
        </BasicTooltip>
      ) : (
        <TableCell key={item + index}>{item}</TableCell>
      )
    } else {
      return item.value.length > 30 ? (
        <BasicTooltip title={item.value}>
          <TableCell key={item.value + index}>
            {item.value.slice(0, 30) + '...'}
          </TableCell>
        </BasicTooltip>
      ) : (
        <TableCell key={item.value + index}>{item.value}</TableCell>
      )
    }
  }

  return (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headRows.map((row, index) => getItem(row, index))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableWrapper
