import React, { FC } from 'react'
import Pagination from '@mui/material/Pagination'
import { Container } from './styles'

interface IPageHeader {
  count?: number
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void
  page: number
  disabled?: boolean
}

const PaginationWrapper: FC<IPageHeader> = ({
  count = 10,
  onChange,
  page,
  disabled
}) => {
  return (
    <Container>
      <Pagination
        count={count}
        disabled={!!disabled}
        onChange={onChange}
        page={page}
      />
    </Container>
  )
}

export default PaginationWrapper
