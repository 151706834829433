import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import './App.css'
import ErrorFallback from 'cellar/src/components/ErrorFallback'
import { AuthProvider } from './contexts/auth'
import MainRoutes from './components/MainRoutes'

function App() {
  return (
    <div className="App">
      <div className="App-main">
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // Reset any state if needed when user retries
          }}
        >
          <AuthProvider>
            <MainRoutes />
          </AuthProvider>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default App
