import React from 'react'
import { Img } from './styles'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
  srcSet?: string
  sizes?: string
  width?: string | number
  height?: string | number
  className?: string
  loading?: 'lazy' | 'eager'
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  srcSet,
  sizes,
  width,
  height,
  loading = 'lazy'
}) => {
  return (
    <Img
      src={src}
      alt={alt}
      srcSet={srcSet}
      sizes={sizes}
      width={width}
      height={height}
      loading={loading as 'lazy' | 'eager'}
    />
  )
}

export default Image
