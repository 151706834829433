import styled from 'styled-components'
import Typography from '@mui/material/Typography'

export const ContainerItem = styled.div`
  padding: 2rem;
`

export const Header = styled.div`
  display: flex;
  place-content: space-between;
  margin-bottom: 20px;
  place-items: center;
`
export const LoadingWrapper = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const NoResultFound = styled(Typography)({
  textAlign: 'center',
  paddingTop: '10%'
})

export const SearchBlock = styled.div`
  margin-right: 10px;

  @media (max-width: 480px) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
`
