import React, { FC, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { ErrorType } from 'cellar/src/ts-types'
import { resetPasswordInitialState } from 'cellar/src/constants'
import PasswordInput from 'cellar/src/components/PasswordInput'
import {
  allValidateValue,
  deepClone,
  FormStateType,
  getErrorMessage,
  validateValue
} from 'cellar/src/utils'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import { resetPassword } from 'cellar/src/api'

interface IResetPasswordModalProps {
  handleClose: () => void
  showSuccessMessage: (type: string) => void
}

const ResetPasswordModal: FC<IResetPasswordModalProps> = ({
  handleClose,
  showSuccessMessage
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [state, setState] = useState<FormStateType>(resetPasswordInitialState)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name in state) {
      const newState: FormStateType = validateValue(state, name, value)
      setState(newState)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onConfirm()
    }
  }

  const resetPasswordApi = () => {
    ;(async () => {
      try {
        const oldPassword = state.oldPassword.value
        const newPassword = state.newPassword.value
        const { result } = await resetPassword({ oldPassword, newPassword })
        if (result) {
          showSuccessMessage('success')
        } else {
          setErrorMessage('error')
        }
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
      } finally {
        setLoading(false)
      }
    })()
  }

  const onConfirm = () => {
    setLoading(true)
    const result = allValidateValue<FormStateType>(state)

    if (result) {
      if (state.newPassword.value !== state.confirmPassword.value) {
        ;(result as FormStateType).confirmPassword.error =
          'Please make sure both fields have the same password.'
      }
      setState(result as FormStateType)
      setLoading(false)
    } else {
      if (state.newPassword.value !== state.confirmPassword.value) {
        const newState = deepClone<FormStateType>(state)
        newState.confirmPassword.error =
          'Please make sure both fields have the same password.'
        setState(newState)
        setLoading(false)
      } else {
        resetPasswordApi()
      }
    }
  }

  return (
    <>
      <CustomizedDialogs
        open={true}
        handleClose={handleClose}
        title="Delete"
        confirmTitle="Save"
        onConfirm={onConfirm}
        maxWidth="xs"
        disabled={loading}
      >
        <form>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                autoFocus
                label="Old Password"
                name="oldPassword"
                value={state.oldPassword.value}
                error={!!state.oldPassword.error}
                helperText={state.oldPassword.error}
                onChange={onChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <PasswordInput
                value={state.newPassword.value}
                name="newPassword"
                label="New Password"
                error={state.newPassword.error}
                onChange={onChange}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <PasswordInput
                value={state.confirmPassword.value}
                name="confirmPassword"
                label="Confirm Password"
                error={state.confirmPassword.error}
                onChange={onChange}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              {errorMessage ? (
                <Typography color="error">{errorMessage}</Typography>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </form>
      </CustomizedDialogs>
    </>
  )
}

export default ResetPasswordModal
