import React, { FC } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { RadioOptions } from 'cellar/src/ts-types'

interface IRadioBtnProps {
  label?: string
  name: string
  value: string
  options: RadioOptions
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  row?: boolean
}

const RadioBtn: FC<IRadioBtnProps> = ({
  label,
  name,
  value,
  options,
  handleChange,
  row
}) => {
  return (
    <FormControl>
      {label ? (
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      ) : (
        ''
      )}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        value={value}
        name={name}
        row={!!row}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioBtn
