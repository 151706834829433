// import React, { FC } from 'react'
// import { IProjectType } from '@repo/types'
// import Box from '@mui/material/Box'
// import Chip from '@mui/material/Chip'
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
// import Stack from '@mui/material/Stack'
// import Typography from '@mui/material/Typography'
// import BasicTooltip from 'cellar/src/components/UI/BasicTooltip'
// import { EditDataType } from '../ts-types'
//
// interface IBodyProps {
//   state: IProjectType[]
//   setCreationModal: (data: EditDataType) => void
//   setDeleteModal: (data: string) => void
// }
//
// const Body: FC<IBodyProps> = ({ state, setCreationModal, setDeleteModal }) => {
//   const handleEdit = (type: IProjectType, index: number) => {
//     setCreationModal({
//       data: type,
//       index: index
//     })
//   }
//
//   return (
//     <Box sx={{ minHeight: '500px', alignContent: 'center' }}>
//       {state.map((type, index) => (
//         <Chip
//           sx={{
//             margin: '5px'
//           }}
//           color="primary"
//           variant="outlined"
//           key={type._id}
//           label={
//             <Stack direction="row" spacing={1} alignItems="center">
//               {type.name.length > 30 ? (
//                 <BasicTooltip title={type.name}>
//                   <Typography variant="body2">
//                     {type.name.slice(0, 30) + '...'}
//                   </Typography>
//                 </BasicTooltip>
//               ) : (
//                 <Typography variant="body2">{type.name}</Typography>
//               )}
//
//               <EditOutlinedIcon
//                 cursor="pointer"
//                 fontSize="small"
//                 color="primary"
//                 onClick={() => handleEdit(type, index)}
//               />
//               <DeleteOutlineOutlinedIcon
//                 cursor="pointer"
//                 color="error"
//                 fontSize="small"
//                 onClick={() => setDeleteModal(type._id)}
//               />
//             </Stack>
//           }
//         />
//       ))}
//     </Box>
//   )
// }
//
// export default Body

import React, { FC, useContext } from 'react'
import { IProject, IProjectType } from '@repo/types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BasicTooltip from 'cellar/src/components/UI/BasicTooltip'
import { EditDataType } from '../ts-types'
import TableWrapper from '../../../components/TableWrapper'
import { noResultFound, projectTypesHeadRows } from '../../../constants'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Image from '../../../components/UI/Image'
import StringCuter from '../../../components/StringCuter'
import Actions from '../../../components/Actions'
import { NoResultFound } from '../../../globalStyles/styles'
import { formatDate } from '../../../utils'
import { UserContext } from '../../../contexts/users'

interface IBodyProps {
  state: IProjectType[]
  setCreationModal: (data: EditDataType) => void
  setDeleteModal: (data: string) => void
}

const Body: FC<IBodyProps> = ({ state, setCreationModal, setDeleteModal }) => {
  const context = useContext(UserContext)
  const handleEdit = (type: IProjectType, index: number) => {
    setCreationModal({
      data: type,
      index: index
    })
  }

  const getCreatedByValue = (id: string) => {
    return context.state.hashMap[id] ? context.state.hashMap[id].email : ''
  }

  return (
    <div>
      {state.length ? (
        <TableWrapper headRows={projectTypesHeadRows}>
          {state.map((projectType: IProjectType, index: number) => (
            <TableRow
              key={projectType._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StringCuter value={projectType.name} />
              <StringCuter
                value={
                  projectType.createdBy
                    ? getCreatedByValue(projectType.createdBy)
                    : 'System'
                }
              />
              <TableCell>
                {formatDate(new Date(projectType?.createdAt as Date))}
              </TableCell>
              <TableCell>
                {formatDate(new Date(projectType?.updatedAt as Date))}
              </TableCell>
              <TableCell>
                <Actions
                  deleteDisabled={!projectType.createdBy}
                  editDisabled={!projectType.createdBy}
                  onDelete={() => {
                    setDeleteModal(projectType?._id as string)
                  }}
                  onEdit={() => {
                    handleEdit(projectType, index)
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableWrapper>
      ) : (
        <NoResultFound variant="h3">{noResultFound}</NoResultFound>
      )}
    </div>
  )
}

export default Body
