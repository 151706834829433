import React, { Dispatch, FC, useEffect, useRef } from 'react'
import CreateEditForm from './CreateEditForm'
import { IQuest } from '@repo/types'
import { StatusModalType } from '../ts-types'
import StatusChangeModal from './StatusChangeModal'
import MessageSnack from '../../../components/MessageSnack'
import DeleteModal from '../../../components/DeleteModal'
import { projectDeleteText, STEPS } from '../../../constants'
import { ErrorType } from '../../../ts-types'
import { deleteQuests } from '../../../api'
import { getErrorMessage } from '../../../utils'

interface IModalsProps {
  createEditData: boolean | IQuest
  setCreationModal: (value: boolean) => void
  afterQuestCreation: (quest?: IQuest) => void
  errorMessage: ErrorType
  setErrorMessage: (message: ErrorType) => void
  successMessage: string
  setSuccessMessage: (message: string) => void
  deleteModal: boolean | string
  setDeleteModal: (value: boolean | string) => void
  onAfterDelete: () => void
  setStatusModal: (value: boolean | StatusModalType) => void
  statusModal: boolean | StatusModalType
  afterUpdate: (value: string) => void
}

const Modals: FC<IModalsProps> = ({
  createEditData,
  setCreationModal,
  afterQuestCreation,
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  deleteModal,
  setDeleteModal,
  onAfterDelete,
  setStatusModal,
  statusModal,
  afterUpdate
}) => {
  const onDelete = (cb: (value: boolean) => void) => {
    ;(async () => {
      try {
        const { result } = await deleteQuests(deleteModal as string)
        if (result) {
          setSuccessMessage('Quest successfully deleted')
        }
        setDeleteModal(false)
        onAfterDelete()
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        cb(false)
      }
    })()
  }

  const onCreateEditYes = (quest?: IQuest) => {
    if (quest) {
      setCreationModal(false)
      afterQuestCreation(quest)
    } else {
      setCreationModal(false)
      afterQuestCreation(quest)
    }
  }

  return (
    <>
      {createEditData && (
        <CreateEditForm
          data={createEditData}
          onYes={onCreateEditYes}
          handleClose={() => setCreationModal(false)}
        />
      )}

      {errorMessage ? (
        <MessageSnack
          onClose={() => {
            setErrorMessage(null)
          }}
          severity="error"
          message={errorMessage}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <MessageSnack
          onClose={() => {
            setSuccessMessage('')
          }}
          severity="success"
          message={successMessage}
        />
      ) : (
        ''
      )}

      {deleteModal ? (
        <DeleteModal
          open={!!deleteModal}
          onYes={onDelete}
          handleClose={() => setDeleteModal(false)}
          text={projectDeleteText}
        />
      ) : (
        ''
      )}

      {statusModal ? (
        <StatusChangeModal
          data={statusModal as StatusModalType}
          afterUpdate={afterUpdate}
          handleClose={() => {
            setStatusModal(false)
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Modals
