export enum AdminStatusEnum {
  ACTIVE = 'active',
  PENDING = 'pending',
  DELETED = 'deleted'
}

export enum AdminRoleEnum {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin'
}
