import React, { useContext, useState } from 'react'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { ContainerItem } from 'cellar/src/globalStyles/styles'
import { ProjectTypeContext } from 'cellar/src/contexts/projectTypes'
import PaginationWrapper from 'cellar/src/components/PaginationWrapper'
import { EditDataType } from './ts-types'
import Header from './components/Header'
import Modals from './components/Modals'
import Body from './components/Body'
import { ErrorType } from '../../ts-types'
import { useQueryParams } from 'cellar/src/hooks'
import {
  deleteProjectItem,
  filterProjectTypes
} from '../../contexts/projectTypes/actions'
import { PAGE_CHANGE, UPDATE_PROJECT_TYPE } from '../../contexts/users/types'
import {
  CREATE_NEW_PROJECT_TYPE,
  RESET_PROJECT_TYPES
} from 'cellar/src/contexts/projectTypes/types'
import { IProjectType } from '@repo/types'

function ProjectTypes() {
  const { getQueryParam, setQueryParam, setQueryParams } = useQueryParams()
  const { dispatch, state } = useContext(ProjectTypeContext)
  const [deleteModal, setDeleteModal] = useState<boolean | string>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [query, setQuery] = useState<string>(getQueryParam('typeQuery') || '')
  const [creationModal, setCreationModal] = useState<EditDataType | boolean>(
    false
  )

  const getListData = () => {
    const data = state.filteredData ? state.filteredData : state.data
    const sliceFrom = (state.page - 1) * 10
    const sliceTo = state.page * 10
    return data.slice(sliceFrom, sliceTo)
  }

  const listData = getListData()

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams([
      {
        key: 'typeQuery',
        value: event.target.value
      },
      { key: 'typePage', value: '1' },
      { key: 'perPage', value: '10' }
    ])
    setQuery(event.target.value)
  }

  const afterDelete = () => {
    let page = state.page
    if (listData.length === 1 && page != 1) {
      page = page - 1
      setQueryParam('typePage', page.toString())
    }
    deleteProjectItem(dispatch, state, deleteModal as string, page)
  }

  const pageChange = (e: React.ChangeEvent<unknown> | null, page: number) => {
    if (page !== state.page) {
      dispatch({ type: PAGE_CHANGE, payload: page })
      setQueryParam('typePage', page.toString())
    }
  }

  const filterDataByQuery = (query: string) => {
    setQueryParam('typePage', '1')
    if (query) {
      filterProjectTypes(dispatch, state.data, query)
    } else {
      dispatch({ type: RESET_PROJECT_TYPES, payload: '' })
    }
  }

  const afterCreate = (newType: IProjectType, isEdit?: boolean) => {
    if (isEdit) {
      dispatch({ type: UPDATE_PROJECT_TYPE, payload: newType })
    } else {
      dispatch({ type: CREATE_NEW_PROJECT_TYPE, payload: newType })
      setQueryParams([
        { key: 'typePage', value: '1' },
        { key: 'perPage', value: '10' },
        { key: 'typeQuery', value: '' }
      ])
      setQuery('')
    }
  }

  return (
    <>
      <FadeTransition>
        <ContainerItem>
          <Header
            query={query}
            onSearch={onSearch}
            setCreationModal={setCreationModal}
            filterDataByQuery={filterDataByQuery}
          />
          {listData ? (
            <Body
              setDeleteModal={setDeleteModal}
              setCreationModal={setCreationModal}
              state={listData}
            />
          ) : (
            ''
          )}

          {state?.totalCount > 10 ? (
            <div>
              <PaginationWrapper
                count={state?.pageCount}
                page={Number(state.page)}
                onChange={pageChange}
              />
            </div>
          ) : (
            ''
          )}
          <Modals
            afterDelete={afterDelete}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            afterCreate={afterCreate}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            creationModal={creationModal}
            setCreationModal={setCreationModal}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />
        </ContainerItem>
      </FadeTransition>
    </>
  )
}

export default ProjectTypes
